import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import ModifyShiftPatternDialog from '../Shifts/ModifyShiftPatternDialog';
import InputAdornment from '@mui/material/InputAdornment';
import BulkUploadStaff from './BulkUploadStaff';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendRounded from '@mui/icons-material/SendRounded';

interface IProps {
	open: boolean;
	onClose: (refresh: boolean) => void;
}

export default function CreateStaff({ open, onClose }: IProps) {
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const { show } = React.useContext(AlertContext);
	const [shiftPatterns, setShiftPatterns] = React.useState<DropdownViewModel[]>([]);
	const [openShiftDialog, setOpenShiftDialog] = React.useState(false);
	const [openBulk, setOpenBulk] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());
	const [stripeLoading, setStripeLoading] = React.useState(false);

	const formContext = useForm({
		defaultValues: { firstName: '', lastName: '', email: '', startDate: null, entitlement: 28, shiftPattern: null }
	});
	const { reset } = formContext;

	React.useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const token = await authService.getAccessToken();

		crabFetch('Shift/GetShiftPatternDropdown', {
			method: 'GET',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
		}, ResponseType.JSON,
			(data: any) => {
				setShiftPatterns(data);
			}
		);

		crabFetch(`Billing/GetSubscriptionStatus`, {
			method: 'GET',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
		}, ResponseType.JSON,
			(data: any) => {
				setSubscriptionStatus(data);
			}
		);
	}

	const goToUpdateSeats = async () => {
		const token = await authService.getAccessToken();
		setStripeLoading(true);

		crabFetch('Billing/GoToUpdateSeats?returnSlug=Staff', {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
		}, ResponseType.Text,
			(data: any) => {
				window.location.href = data;
			}
		);
	}

	const submit = async (form: any) => {
		const token = await authService.getAccessToken();
		setLoading(true);

		if (form.startDate) {
			const offset = form.startDate.utcOffset();
			form.startDate = form.startDate.add(offset, 'm');
		}

		crabFetch('Staff/AddStaff', {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			body: JSON.stringify(form)
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', "Successfully created new staff member");
					closeDialog(true);
				}
				setLoading(false);
				getData();
			},
			(error: any) => {
				show('error', error);
				setLoading(false);
			}
		);
	}

	const closeDialogs = (refresh: boolean) => {
		setOpenShiftDialog(false);
		setOpenBulk(false);

		if (refresh) {
			getData();
		}
	}

	const closeDialog = (refresh: boolean) => {
		reset();
		onClose(refresh);
	}

	const handleClose = (event: any, reason: any) => {
		if (reason && reason === "backdropClick")
			return;
		onClose(false);
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
			>
				{(subscriptionStatus.seatsInSubscription === subscriptionStatus.usedSeats && subscriptionStatus.status !== 'trialing') &&
					<Grid item>
						<Alert variant="outlined" severity="info">You've used <b>{subscriptionStatus.usedSeats} of {subscriptionStatus.seatsInSubscription}</b> paid seats. To add more staff, add more seats: <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToUpdateSeats}>Manage Subscription</LoadingButton></Alert>
						<Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
					</Grid>
				}
				{(subscriptionStatus.usedSeats < subscriptionStatus.seatsInSubscription || subscriptionStatus.status === 'trialing') &&
					<FormContainer
						formContext={formContext}
						onSuccess={submit}
					>
						<DialogTitle>
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>Invite Staff Member</Grid>
								<Grid item><Button onClick={() => setOpenBulk(true)} variant="outlined">Bulk Invite Staff</Button></Grid>
							</Grid>
						</DialogTitle>
						<DialogContent>
							<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
										<TextFieldElement autoComplete='off' name="firstName" required fullWidth size="small" />
									</Grid>
									<Grid item xs={12}>
										<InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
										<TextFieldElement autoComplete='off' name="lastName" required fullWidth size="small" />
									</Grid>
									<Grid item xs={12}>
										<InputLabel htmlFor="email" shrink>Email</InputLabel>
										<TextFieldElement autoComplete='off' name="email" required fullWidth size="small" />
									</Grid>
									<Grid item xs={12}>
										<InputLabel htmlFor="startDate" shrink>Start Date of Employment</InputLabel>
										<DatePickerElement name="startDate" required inputProps={{ fullWidth: true, size: "small" }} />
									</Grid>
									<Grid item xs={12}>
										<InputLabel htmlFor="entitlement" shrink>FTE Entitlement (in Days)</InputLabel>
										<TextFieldElement autoComplete='off' InputProps={{
											endAdornment: <InputAdornment position="end">days</InputAdornment>,
										}} name="entitlement" required fullWidth size="small" />
									</Grid>
									<Grid item xs={12}>
										<InputLabel htmlFor="shiftPattern" shrink>Select Shift Pattern</InputLabel>
										<SelectElement name="shiftPattern" required options={shiftPatterns} fullWidth size="small" />
									</Grid>
									<Grid item xs={12}>
										<Button variant="outlined" onClick={() => setOpenShiftDialog(true)}>Create New Shift Pattern</Button>
									</Grid>
								</Grid>
							</LocalizationProvider>
							<DialogActions>
								<LoadingButton loading={loading} variant="contained" type="submit" fullWidth endIcon={<SendRounded />}>Invite</LoadingButton>
								<Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
							</DialogActions>
						</DialogContent>
					</FormContainer>
				}
			</Dialog>
			<ModifyShiftPatternDialog open={openShiftDialog} onClose={closeDialogs} />
			<BulkUploadStaff open={openBulk} onClose={closeDialogs} />
		</>
	);
}