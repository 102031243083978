import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EventListViewModel from '../Viewmodels/EventListViewModel';
import ColouredRow from '../../Utilities/ColouredRow';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as Icons from "@mui/icons-material";
import authService from '../../api-authorization/AuthorizeService';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';

interface IProps {
    teamView: number | undefined;
    viewRequest: (request: number | null, date: Date | null, teamId: number | null) => void;
    selected: number | null;
}

export default function EventRequestList(props: IProps) {
    const { teamView, viewRequest, selected } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [events, setEvents] = React.useState<EventListViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getData();
    }, [teamView, selected]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Event/GetStaffEventRequests${teamView ? `?teamId=${teamView}` : ``}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: EventListViewModel[]) => {
                setLoading(false)
                setEvents(data);
            }
        );
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h2">Pending Event Requests</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ border: 1, borderColor: 'primary.main' }}>
                    <Grid container spacing={{ xs: 1, xl: 2 }} alignItems="center" justifyContent="center">
                        {loading &&
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        }
                        {!loading && events.length <= 0 &&
                            <Grid item>
                                <Typography>No Events</Typography>
                            </Grid>
                        }
                        {events.map((item, index) => {
                            const split = item.userName.split(' ');
                            const Icon = Icons[item.icon] ?? <React.Fragment/>;

                            return <Grid item xs={12} key={'dayEvent_' + index}>
                                <ColouredRow colour={item.colour} backgroundColour={'#F2F4F8'}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Icon style={{ color: item.colour, verticalAlign: "middle" }} />
                                        </Grid>
                                        <Grid item>
                                            <Avatar>{split[0].charAt(0)}{split[1].charAt(0)}</Avatar>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography sx={{ color: 'primary.main' }}>{item.userName}</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography><b>{new Date(item.start).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })}</b> {new Date(item.start).toDateString() !== new Date(item.end).toDateString() && <>to <b>{new Date(item.end).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })}</b></>}</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button onClick={() => viewRequest(item.leaveId, item.start, item.teamId)} disabled={loading}>Review</Button>
                                        </Grid>
                                    </Grid>
                                </ColouredRow>
                            </Grid>;
                        })}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}