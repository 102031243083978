import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OnboardingViewModel from './Viewmodels/OnboardingViewModel';
import { useForm } from 'react-hook-form';
import { monthDayValues, Months } from '../../Utilities/CalendarEnums';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper/Stepper';
import { AlertContext } from '../../Contexts/AlertContext';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const dayOfWeekOptions = [
	{ id: 0, label: 'Sunday' },
	{ id: 1, label: 'Monday' },
	{ id: 2, label: 'Tuesday' },
	{ id: 3, label: 'Wednesday' },
	{ id: 4, label: 'Thursday' },
	{ id: 5, label: 'Friday' },
	{ id: 6, label: 'Saturday' },
];

const ukCountries = [
	{ id: 0, label: 'England' },
	{ id: 1, label: 'Scotland' },
	{ id: 2, label: 'Wales' },
	{ id: 3, label: 'Northern Ireland' },
];

type ContextType = {

};

type Props = { children: React.ReactNode };

export const OnboardContext = React.createContext<ContextType>({});

export default function OnboardingProvider({ children }: Props) {
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const { show } = React.useContext(AlertContext);
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [onboarded, setConfirmedOnboarding] = React.useState(false);
	const authSubscription = authService.subscribe(() => checkOnboarding());
	const [activeStep, setActiveStep] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	//Use one use effect and make a handle change function
	// context https://youtu.be/-yIsQPp31L0?feature=shared 8:00
	const [isFirstNameFilled, setFirstFilled] = React.useState(false);
	const [isSurnameFilled, setSurnameFilled] = React.useState(false);
	const [isCompanyNameFilled, setCompanyNameFilled] = React.useState(false);
	const [isCountryFilled, setCountryFilled] = React.useState(false);

	const formContext = useForm<OnboardingViewModel>({
		defaultValues: new OnboardingViewModel()
	});

	React.useEffect(() => {
		if (!page.includes('RegisterConfirmation')) {
			checkOnboarding();
		}

		return () => {
			authService.unsubscribe(authSubscription);
		}
	}, []);

	const checkOnboarding = async () => {
		const authenticated = await authService.isAuthenticated();
		if (!authenticated) {
			navigate(ApplicationPaths.Login);
		}
		else {
			const token = await authService.getAccessToken();
			crabFetch('User/GetOnboardingStatus', {
				headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			}, ResponseType.JSON,
				(data: any) => {
					setOpenDialog(!data);
					setConfirmedOnboarding(data);
				}
			);
		}
	}

	const submit = async (form: any) => {
		const token = await authService.getAccessToken();

		const user = await authService.getUser();

		form.id = user.sub;

		setLoading(true);

		crabFetch('Company/Onboard', {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			body: JSON.stringify(form)
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					updateAuthUserState();
					show('success', "Successfully added company details");
					setOpenDialog(false);
					setConfirmedOnboarding(true);
				}
				setLoading(false);
			},
			(error: any) => {
				show('error', error);
				setLoading(false);
			}
		);
	}

	const updateAuthUserState = async () => {
		await authService.updateUser();
	}

	const page = window.location.pathname;

	const watchFromMonth = formContext.watch('holidayFromMonth');
	const monthList = monthDayValues.map((item) => {
		return { id: item.month, label: Months[item.month - 1].toString() };
	});

	const fromDayOptions = React.useMemo(() => {
		if (watchFromMonth) {
			return Array.from({ length: monthDayValues[watchFromMonth - 1].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
		} else
			return Array.from({ length: monthDayValues[0].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
	}, [watchFromMonth]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const preventSubmission = (e: any) => {
		const keyCode = e.keyCode ? e.keyCode : e.which;

		if (keyCode === 13)
			e.preventDefault();
	};

	return (
		<OnboardContext.Provider value={{}}>
			<Dialog
				open={openDialog}
				maxWidth="md"
				fullWidth
			>
				<FormContainer
					formContext={formContext}
					onSuccess={submit}
				>
					<DialogTitle>New Company Set Up</DialogTitle>
					<DialogContent>
						<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
							<Stepper activeStep={activeStep} orientation="vertical">
								<Step>
									<StepLabel>
										Your Details
									</StepLabel>
									<StepContent>
										<Grid container spacing={1.5}>
											<Grid item xs={7}>
												<InputLabel htmlFor="adminFirstName" shrink>Your First Name</InputLabel>
												<TextFieldElement
													autoComplete='off'
													name="adminFirstName"
													required
													fullWidth
													size="small"
													onKeyPress={preventSubmission}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setFirstFilled(event.target.value.length > 0 ? true : false);
													}} />
											</Grid>
											<Grid item xs={7}>
												<InputLabel htmlFor="adminSurename" shrink>Your Surname</InputLabel>
												<TextFieldElement
													autoComplete='off'
													name="adminSurename"
													required
													fullWidth
													size="small"
													onKeyPress={preventSubmission}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setSurnameFilled(event.target.value.length > 0 ? true : false);
													}} />
											</Grid>
											<Grid item xs={7}>
												<Button disabled={!(isSurnameFilled && isFirstNameFilled)} variant="contained" onClick={handleNext}>Continue</Button>
											</Grid>
										</Grid>
									</StepContent>
								</Step>
								<Step>
									<StepLabel>
										Company Name
									</StepLabel>
									<StepContent>
										<Typography>Please provide your Company Name</Typography>
										<Grid container spacing={1.5}>
											<Grid item xs={7}>
												<InputLabel htmlFor="companyName" shrink>Company Name</InputLabel>
												<TextFieldElement
													autoComplete='off'
													name="companyName"
													required
													fullWidth
													size="small"
													onKeyPress={preventSubmission}
													onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
														setCompanyNameFilled(event.target.value.length > 0 ? true : false);
													}} />
											</Grid>
											<Grid item xs={7}>
												<Button disabled={!isCompanyNameFilled} variant="contained" onClick={handleNext}>Continue</Button>
												<Button onClick={handleBack}>Back</Button>
											</Grid>
										</Grid>
									</StepContent>
								</Step>
								<Step>
									<StepLabel>
										Holiday Details
									</StepLabel>
									<StepContent>
										<Grid container spacing={1.5}>
											<Grid item xs={7}>
												<Typography>Please select the country you operate in for the public holidays to be applied to your calendar</Typography>
												<InputLabel htmlFor="country" shrink>Country</InputLabel>
												{ukCountries.length > 0 &&
													<SelectElement
														size='small'
														value="Countries"
														sx={{ minWidth: 50 }}
														fullWidth
														required
														options={ukCountries}
														name="country"
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															setCountryFilled(true);
														}}
													/>}
											</Grid>
											<Grid item xs={7}>
												<Typography>Please choose when your company's holiday year starts</Typography>
												<InputLabel htmlFor="holidayFrom" shrink>Holiday From</InputLabel>
												<SelectElement
													size='small'
													name="holidayFromMonth"
													required
													options={monthList}
													sx={{ pr: 2 }} />
												{fromDayOptions.length > 0 &&
													<SelectElement
														size='small'
														name="holidayFromDay"
														required
														options={fromDayOptions}
													/>}
											</Grid>
											<Grid item xs={7}>
												<Button disabled={!isCountryFilled} variant="contained" onClick={handleNext}>Continue</Button>
												<Button onClick={handleBack}>Back</Button>
											</Grid>
										</Grid>
									</StepContent>
								</Step>
								<Step>
									<StepLabel>
										Final Step
									</StepLabel>
									<StepContent>
										<Grid container spacing={1.5}>
											<Grid item xs={7}>
												<Typography>Please enter the default number of paid hours for a full time week.</Typography>
												<InputLabel htmlFor="fullTimeHours" shrink>Standard Full Time Hours</InputLabel>
												<TextFieldElement
													autoComplete='off'
													size='small'
													onKeyPress={preventSubmission}
													InputProps={{
														endAdornment: <InputAdornment position="end">hours</InputAdornment>,
													}}
													name="fullTimeHours"
													required
													type={'number'}
													fullWidth />
											</Grid>
											<Grid item xs={7}>
												<Typography>Please enter the default yearly holiday entitlement for a full time employee (including public holidays)</Typography>
												<InputLabel htmlFor="entitlement" shrink>Standard Full Time Entitlement</InputLabel>
												<TextFieldElement
													autoComplete='off'
													size='small'
													onKeyPress={preventSubmission}
													InputProps={{
														endAdornment: <InputAdornment position="end">days</InputAdornment>,
													}}
													name="entitlement"
													required
													type={'number'}
													fullWidth />
											</Grid>
											<Grid item xs={7}>
												<Typography>Please select the first day of your working week</Typography>
												<InputLabel htmlFor="workingWeekStart" shrink>Working Week Start Day</InputLabel>
												<SelectElement size='small' name="workingWeekStart" required fullWidth options={dayOfWeekOptions} />
											</Grid>
											<Grid item xs={12}>
												<LoadingButton loading={loading} variant="contained" type="submit">Submit</LoadingButton>
												<Button onClick={handleBack}>Back</Button>
											</Grid>
										</Grid>
									</StepContent>
								</Step>
							</Stepper>
						</LocalizationProvider>
					</DialogContent>
				</FormContainer>
			</Dialog>
			{(page.includes('/authentication') || page.includes('/Identity') || page.includes('RegisterConfirmation') || onboarded) && children}
		</OnboardContext.Provider>
	);
}