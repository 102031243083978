import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import NumberedWeekDay from './Viewmodels/NumberedWeekDay';
import { darken, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { DayTypes } from '../Utilities/CalendarEnums';
import EventRowViewModel from './Viewmodels/EventRowViewModel';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as Icons from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(
    ({ theme }) => `    
            border-right: 1px ${theme.palette.background.default} solid;
            border-bottom: 1px ${theme.palette.background.default} solid;
            background-color: ${theme.palette.background.paper} !important;
            padding: 6px;
            max-height: 36px;
    `);

interface IProps {
    rowEvents: EventRowViewModel[];
    modifyEvent: (eventId: number) => void;
    selected: Date;
    weekStart: number;
    weekDay: NumberedWeekDay;
    nonShifted: number[];
    start: number;
    current: number;
    end: number;
    selecting: boolean;
    setSelecting: (selecting: boolean) => void;
    endSelect: (day: number) => void;
    setStart: (start: number) => void;
    setCurrent: (current: number) => void;
    clickable: boolean;
}

export default function MonthViewCell(props: IProps) {
    const { rowEvents, weekDay, modifyEvent, selected, weekStart, nonShifted, start, current, end, endSelect, setStart, setCurrent, selecting, setSelecting, clickable } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const eventMenuClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
        if (events.filter(f => f.leaveId).length > 0)
            setAnchorEl(event.currentTarget);
    };
    const handleEventMenuClose = () => {
        setAnchorEl(null);
    };

    const events = rowEvents.filter(f => new Date(f.dayStart).getDate() === weekDay.day && new Date(f.dayEnd).getDate() === weekDay.day);
    const notWorking = React.useMemo(() => {
        return nonShifted.findIndex(f => f === weekDay.day) > -1
    }, [nonShifted, weekDay]);
    const selectingCell = (selecting && start <= weekDay.day && (current >= weekDay.day || end >= weekDay.day));
    const selectStyle = React.useMemo(() => {
        if (selected.getDate() === weekDay.day) {
            return {
                borderRight: `2px #456FEC solid`,
                borderLeft: `2px #456FEC solid`
            };
        } else {
            return {};
        }
    }, [selected, weekDay]);

    const cell = React.useMemo(() => {
        if (events && events.length > 0) {
            return {
                maxHeight: '36px',
                maxWidth: selected.getDate() === weekDay.day ? '36px' : '37px',
                verticalAlign: "middle",
                cursor: 'pointer',
                backgroundColor: 'transparent',
                borderLeft: weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                borderRight: weekDay.dayWeekNum !== weekStart ? `1px #F2F4F8 solid` : ''
            };
        } else {
            return {
                maxWidth: '36px',
                backgroundColor: selectingCell ? darken('#fff', 0.25) + ' !important' : notWorking ? `#F2F4F8 !important` : `#fff !important`,
                borderLeft: weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                borderRight: weekDay.dayWeekNum !== weekStart ? `1px #F2F4F8 solid` : '',
                cursor: 'pointer',
            };
        }
    }, [events, notWorking, selected, weekDay]);

    const style = React.useMemo(() => {
        return { ...cell, ...selectStyle }
    }, [cell, selectStyle]);

    let eventCell: JSX.Element | JSX.Element[] = <React.Fragment />;

    if (events && events.length > 0) {
        let flipDiag = false;

        const pendingMultidayEvents = rowEvents.filter(f => f.status === 'Pending' && new Date(f.start).getDate() !== new Date(f.end).getDate());

        if (events.length > 1) {
            eventCell = events.map((event, index) => {
                let splitStyle: any;

                if (event.status === 'Pending') {
                    if (pendingMultidayEvents.findIndex(f => f.leaveId === event.leaveId) > -1) {
                        flipDiag = (new Date(event.dayStart).getDate() - new Date(event.start).getDate()) % 2 > 0;
                    }

                    const eventColour = flipDiag ? darken(event.colour, 0.25) : event.colour;
                    const darkColour = flipDiag ? event.colour : darken(event.colour, 0.25);

                    splitStyle = event.dayType === DayTypes.Morning ? {
                        background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                        clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                        borderLeft: weekDay.dayWeekNum === weekStart ? `px #B8B8B8 solid` : `0px ${event.colour} solid`,
                        borderRight: 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    } : event.dayType === DayTypes.Afternoon ? {
                        background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                        clipPath: 'polygon(calc(100% + 0.5px) 100%, calc(100% + 0.5px) 0, 0 100%)',
                        borderLeft: weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                        borderRight: weekDay.dayWeekNum !== weekStart ? `0px ${event.colour} solid` : '',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    } : {
                        background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                        borderRight: `0px ${event.colour} solid`,
                        position: 'absolute',
                        top: 0,
                        left: 0
                    };
                } else {
                    splitStyle = event.dayType === DayTypes.Morning ? {
                        background: `linear-gradient(to right bottom, ${event.colour} 48%, #fff 50%, transparent 52%)`,
                        borderLeft: weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : `1px ${event.colour} solid`,
                        borderRight: 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    } : event.dayType === DayTypes.Afternoon ? {
                        background: `linear-gradient(to right bottom, transparent 48%, #fff 50%, ${event.colour} 52%)`,
                        borderLeft: weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                        borderRight: weekDay.dayWeekNum !== weekStart ? `1px ${event.colour} solid` : '',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    } : {
                        backgroundColor: selectingCell ? `${darken(event.colour, 0.25)} !important` : `${event.colour} !important`,
                        borderRight: `1px ${event.colour} solid`,
                        position: 'absolute',
                        top: 0,
                        left: 0
                    };
                }

                const eventStyle: any = { ...style, ...splitStyle };

                return <Box sx={eventStyle} key={'multiEventDay' + index}>
                    <div style={{ width: '24px', height: '24px', margin: selected.getDate() === weekDay.day ? '6px 4px' : '6px' }} />
                </Box>;
            });
        } else {
            const event = events[0];
            let splitStyle: any;

            if (event.status === 'Pending') {
                if (pendingMultidayEvents.findIndex(f => f.leaveId === event.leaveId) > -1) {
                    flipDiag = (new Date(event.dayStart).getDate() - new Date(event.start).getDate()) % 2 > 0;
                }

                const eventColour = flipDiag ? darken(event.colour, 0.25) : event.colour;
                const darkColour = flipDiag ? event.colour : darken(event.colour, 0.25);

                splitStyle = event.dayType === DayTypes.Morning ? {
                    background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                    clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                    borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `1px #F2F4F8 solid`,
                    borderLeft: selected.getDate() === weekDay.day ? `2px #456FEC solid` : weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                } : event.dayType === DayTypes.Afternoon ? {
                    background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                    clipPath: 'polygon(calc(100% + 0.5px) 100%, calc(100% + 0.5px) 0, 0 100%)',
                    borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `0px ${event.colour} solid`,
                    borderLeft: selected.getDate() === weekDay.day ? `2px #456FEC solid` : weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                } : {
                    background: `repeating-linear-gradient(45deg, ${selectingCell ? darken(eventColour, 0.25) : eventColour} 0 10%, ${selectingCell ? darken(darkColour, 0.25) : darkColour} 0 20%)`,
                            borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `0px ${event.colour} solid`,
                };
            } else {
                splitStyle = event.dayType === DayTypes.Morning ? {
                    background: `linear-gradient(to right bottom, ${event.colour} 48%, #fff 50%, transparent 52%)`,
                    borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `1px #F2F4F8 solid`,
                    borderLeft: selected.getDate() === weekDay.day ? `2px #456FEC solid` : weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                } : event.dayType === DayTypes.Afternoon ? {
                    background: `linear-gradient(to right bottom, transparent 48%, #fff 50%, ${event.colour} 52%)`,
                    borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `1px ${event.colour} solid`,
                    borderLeft: selected.getDate() === weekDay.day ? `2px #456FEC solid` : weekDay.dayWeekNum === weekStart ? `1px #B8B8B8 solid` : '',
                } : {
                    backgroundColor: selectingCell ? `${darken(event.colour, 0.25)} !important` : `${event.colour} !important`,
                    borderRight: selected.getDate() === weekDay.day ? `2px #456FEC solid` : `1px ${event.colour} solid`,
                };
            }

            const eventStyle = { ...style, ...splitStyle };

            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            let showIcon = false;

            if (startDate.getMonth() !== endDate.getMonth() && startDate.getMonth() === new Date(event.dayStart).getMonth()) {
                const eventsForMonth = rowEvents.filter(f => new Date(f.dayStart).getMonth() === startDate.getMonth()).map(m => new Date(m.dayStart).getDate());
                const lastDayForMonth = eventsForMonth.reduce((a, b) => Math.max(a, b), -Infinity);
                showIcon = lastDayForMonth === weekDay.day;
            }

            let splitIcon = <div style={{ width: '24px', height: '24px' }} />;

            if (event.dayType === DayTypes['All Day'] && (new Date(event.end).getDate() === weekDay.day || showIcon)) {
                const Icon = Icons[event.icon];
                if (Icon) {
                    splitIcon = <Icon sx={{
                        color: 'primary.contrastText',
                        verticalAlign: "middle",
                        marginLeft: selected.getDate() === weekDay.day ? '-1px' : ''
                    }} />;
                }
            }

            if (event.leaveId) {
                if (event.dayType === DayTypes['All Day']) {
                    return <Tooltip title={event.name}>
                        <StyledTableCell align="center"
                            onClick={() => clickable ? modifyEvent(event.leaveId!) : undefined}
                            onMouseUp={() => endSelect(weekDay.day)}
                            sx={eventStyle}
                        >
                            {splitIcon}
                        </StyledTableCell>
                    </Tooltip>;
                } else {
                    return <Tooltip title={event.name}>
                        <StyledTableCell align="center"
                            onMouseDown={() => {
                                setSelecting(true);
                                setStart(weekDay.day);
                                setCurrent(weekDay.day);
                            }}
                            onMouseOver={() => {
                                if (selecting)
                                    setCurrent(weekDay.day);
                            }}
                            onMouseUp={() => {
                                if (start === weekDay.day) {
                                    modifyEvent(event.leaveId!);
                                    setSelecting(false);
                                    setStart(0);
                                    setCurrent(0);
                                } else {
                                    endSelect(weekDay.day);
                                }
                            }}
                            sx={eventStyle}
                        >
                            {splitIcon}
                        </StyledTableCell>
                    </Tooltip>;
                }
            } else {
                return <Tooltip title={event.name}>
                    <StyledTableCell align="center"
                        onMouseDown={() => {
                            if (clickable) {
                                setSelecting(true);
                                setStart(weekDay.day);
                                setCurrent(weekDay.day);
                            }
                        }}
                        onMouseOver={() => {
                            if (selecting && clickable)
                                setCurrent(weekDay.day);
                        }}
                        onMouseUp={() => {
                            if (clickable)
                                endSelect(weekDay.day)
                        }}
                        sx={eventStyle}
                    >
                        {splitIcon}
                    </StyledTableCell>
                </Tooltip>;
            }
        }
    }

    return (
        <>
            {events && events.length > 1 ?
                <React.Fragment>
                    <Tooltip title={<React.Fragment>
                        {events.map((item, index) => <Typography key={'eventName' + index}>{item.name}</Typography>)}
                    </React.Fragment>}>
                        <StyledTableCell align="center"
                            onClick={eventMenuClick}
                            onMouseUp={() => endSelect(weekDay.day)}
                            sx={selectStyle}
                            style={{ position: 'relative' }}
                        >
                            {eventCell}
                        </StyledTableCell>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleEventMenuClose}>
                        {events.filter(f => f.leaveId).map((item, index) =>
                            <MenuItem key={'eventMenu' + index} onClick={() => { handleEventMenuClose(); modifyEvent(item.leaveId!); }}>{item.name}</MenuItem>
                        )}
                    </Menu>
                </React.Fragment> : events && events.length === 1 ?
                    eventCell :
                    <StyledTableCell align="center" valign="middle"
                        onMouseDown={(e) => {
                            e.preventDefault();

                            if (clickable) {
                                setSelecting(true);
                                setStart(weekDay.day);
                                setCurrent(weekDay.day);
                            }

                        }}
                        onMouseOver={() => {
                            if (selecting && clickable)
                                setCurrent(weekDay.day);
                        }}
                        onMouseUp={(e) => {
                            e.preventDefault();

                            if (clickable) {
                                endSelect(weekDay.day)
                            }
                        }}
                        sx={style}
                    >
                        <div style={{ width: '24px', height: '24px' }} />
                    </StyledTableCell>
            }
        </>
    );
}