import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MonthlyEventsViewModel from './Viewmodels/MonthlyEventsViewModel';
import NumberedWeekDay from './Viewmodels/NumberedWeekDay';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MonthViewCell from './MonthViewCell';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { ModifyEvent } from './MonthView';

const StyledTableCell = styled(TableCell)(
    ({ theme }) => `    
            border-right: 1px ${theme.palette.background.default} solid;
            border-bottom: 1px ${theme.palette.background.default} solid;
            background-color: ${theme.palette.background.paper} !important;
            padding: 6px;
            max-height: 36px;
    `);

interface IProps {
    row: MonthlyEventsViewModel;
    daysInMonth: NumberedWeekDay[];
    addEvent: (userId: string, start: number, end: number) => void;
    modifyEvent: (event: ModifyEvent) => void;
    teamName?: string;
    monthFilter: Date;
    selected: Date;
    weekStart: number;
    rowClickable: boolean;
}

export default function MonthViewRow(props: IProps) {
    const navigate = useNavigate();
    const { row, daysInMonth, addEvent, modifyEvent, teamName, monthFilter, selected, weekStart, rowClickable } = props;
    const [selecting, setSelecting] = React.useState(false);
    const [start, setStart] = React.useState(0);
    const [current, setCurrent] = React.useState(0);
    const [end, setEnd] = React.useState(0);

    React.useEffect(() => {
        if (start > 0 && end > 0) {
            start < end ? addEvent(row.id, start, end) : addEvent(row.id, end, start);
            setEnd(0);
            setStart(0);
            setCurrent(0);
            setSelecting(false);
        }
      
    }, [end]);

    const onEndSelect = (day: number) => {
        setSelecting(false);
        setEnd(day);
    }

    const nonShifted = React.useMemo(() => {
        const days: number[] = [];

        if (row.shifts && row.shifts.length > 0) {
            const orderedShifts = [...row.shifts].sort((a, b) => {
                if (dayjs(a.startDate).isBefore(b.startDate, 'day'))
                    return -1;

                if (dayjs(a.startDate).isAfter(b.startDate, 'day'))
                    return 1;

                return 0;
            });


            const employeeStartDate = dayjs(row.startDate);
            const employeeLeaveDate = row.leaveDate ? dayjs(row.leaveDate) : null;
            let shiftIndex = 0;
            let weekIndex = orderedShifts[0].startWeek ? orderedShifts[0].startWeek - 1 : 0;

            const firstDay = new Date(monthFilter.getFullYear(), monthFilter.getMonth(), 1);

            if (!row.shifts[0].weeks || (employeeLeaveDate && employeeLeaveDate.isBefore(firstDay, 'day'))) {
                return daysInMonth.map(m => m.day);
            }

            for (let i = 0; i < daysInMonth.length; i++) {
                const date = new Date(monthFilter.getFullYear(), monthFilter.getMonth(), daysInMonth[i].day);

                if (employeeStartDate.isAfter(date, 'day') || (employeeLeaveDate && employeeLeaveDate.isBefore(date, 'day'))) {
                    days.push(daysInMonth[i].day);
                } else {
                    const shift = orderedShifts[shiftIndex];

                    if (shift.weeks.length > 1) {
                        if (!shift.weeks[weekIndex].days || shift.weeks[weekIndex].days.findIndex(f => f.dayIndex === date.getDay()) === -1)
                            days.push(daysInMonth[i].day);

                        if (date.getDay() === 6) {
                            weekIndex++;

                            if (weekIndex === shift.weeks.length)
                                weekIndex = orderedShifts[0].startWeek ? orderedShifts[0].startWeek - 1 : 0;
                        }
                    } else if (shift.weeks[0].days.findIndex(f => f.dayIndex === date.getDay()) === -1 || shift.weeks.length <= 0) {
                        days.push(daysInMonth[i].day);
                    }

                    if (!(employeeLeaveDate && employeeLeaveDate.toDate().toDateString() !== date.toDateString()) && shift.endDate && date.toDateString() === new Date(shift.endDate).toDateString() && date.getDate() !== daysInMonth[daysInMonth.length - 1].day) {
                        shiftIndex++;
                        weekIndex = orderedShifts[0].startWeek ? orderedShifts[0].startWeek - 1 : 0;
                    }
                }
            }
        }

        return days;
    }, [row.shifts]);

    const profile = () => {
        navigate(
            '/Staff/Management',
            {
                state: {
                    userId: row.id
                }
            }
        );
    }

    const eventsForMonth = React.useMemo(() => {
        if (row.events.length > 0)
            return row.events.filter(f => new Date(f.dayStart).getMonth() === monthFilter.getMonth());
        else
            return [];
    }, [row.events, monthFilter]);

    const modifyEventClick = (eventId: number) => {
        modifyEvent({ eventId, userId: row.id });
    }

    return (
        <>
            {teamName &&
                <TableRow sx={{
                    [`&:last-child > td:nth-of-type(${selected.getDate() + 1})`]: {
                        borderBottom: `2px #456FEC solid`
                    },
                    backgroundColor: '#fff!important'
                }}>
                    <StyledTableCell sx={{
                        position: 'sticky',
                        left: 0,
                        borderRight: `2px #B5ADC4 solid`,
                        zIndex: 900
                    }}
                    >

                        <Typography variant="h3">{teamName}</Typography>
                    </StyledTableCell>
                    {daysInMonth.map(item => {
                        const border = selected.getDate() === item.day ? {
                            borderRight: `2px #456FEC solid`,
                            borderLeft: `2px #456FEC solid`
                        } : item.dayWeekNum === weekStart ? {
                            borderRight: `0px #B8B8B8 solid`,
                            //week start line
                            borderLeft: `1px #B8B8B8 solid`
                        } : {
                            borderRight: `0px #B8B8B8 solid`,
                            '&:last-child': {
                                borderRight: '1px #F2F4F8 solid'
                            }
                        };

                        return <StyledTableCell key={'teamrow' + row.id + '_cell' + item.day
                        }
                            sx={border}
                        >
                            <div style={{ width: '24px', height: '24px' }} />
                        </StyledTableCell>;
                    })}
                </TableRow>
            }
            <TableRow sx={{
                [`&:last-child > td:nth-of-type(${selected.getDate() + 1})`]: {
                    borderBottom: `2px #456FEC solid`,
                    paddingBottom: '5px'
                },
                backgroundColor: '#fff!important'
            }}>
                <StyledTableCell sx={{
                    position: 'sticky',
                    left: 0,
                    borderRight: `2px #B5ADC4 solid`,
                    zIndex: 900,
                    cursor: 'pointer'
                }}
                    onClick={rowClickable ? profile : undefined}
                >
                    <Typography fontWeight={row.isManager ? 'bold' : ''} variant="h4" title={row.name} sx={{ pl: 2, textWrap: 'nowrap', overflow: 'hidden' }} >{row.name}</Typography>
                </StyledTableCell>
                {daysInMonth.map(item => <MonthViewCell key={'row' + row.id + '_cell' + item.day} rowEvents={eventsForMonth} modifyEvent={modifyEventClick} selected={selected} weekDay={item} weekStart={weekStart} nonShifted={nonShifted} endSelect={onEndSelect} setStart={setStart} setCurrent={setCurrent} start={start} current={current} end={end} selecting={selecting} setSelecting={setSelecting} clickable={rowClickable} />)}
            </TableRow>
        </>
    );
}