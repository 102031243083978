import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { CheckboxElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ShiftPatternViewModel from './Viewmodels/ShiftPatternViewModel';
import ShiftPatternWeek from './ShiftPatternWeek';
import ShiftWeekViewModel from './Viewmodels/ShiftWeekViewModel';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    shiftPatternId?: number | null;
    userId?: string;
    showDefault?: boolean;
}

export default function ModifyShiftPatternDialog(props: IProps) {
    const { open, onClose, shiftPatternId, userId, showDefault } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [existingPattern, setExistingPattern] = React.useState<ShiftPatternViewModel>(new ShiftPatternViewModel());
    const [groupedWeeks, setGroupedWeeks] = React.useState<ShiftWeekViewModel[]>([{
        weekIndex: 1,
        days: [
            { id: null, dayIndex: 0, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 1, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 2, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 3, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 4, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 5, weekIndex: 1, active: false, startTime: null, endTime: null },
            { id: null, dayIndex: 6, weekIndex: 1, active: false, startTime: null, endTime: null },
        ],
        totalWorkingHour: 0
    }]);
    const [submitting, setSubmitting] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [currentError, setCurrentError] = React.useState('');
    const [workingWeekStart, setWorkingWeekStart] = React.useState(1);

    const formContext = useForm<ShiftPatternViewModel>({
        defaultValues: new ShiftPatternViewModel()
    });
    const { reset } = formContext;

    React.useEffect(() => {
        getStartDay();
    }, []);

    React.useEffect(() => {
        if (shiftPatternId)
            getData();
    }, [shiftPatternId]);

    React.useEffect(() => {
        if (existingPattern.weeks.length > 0) {
            setWeeks();
        }
    }, [existingPattern]);

    const setWeeks = () => {
        const weeks = [...existingPattern.weeks];

        for (var i = 0; i < weeks.length; i++) {
            for (var j = 0; j < 7; j++) {
                if (weeks[i].days.findIndex(f => f.dayIndex === j) <= -1) {
                    weeks[i].days.push({
                        id: null,
                        dayIndex: j,
                        weekIndex: weeks[i].weekIndex,
                        active: false,
                        startTime: null,
                        endTime: null
                    });
                }
            }

            weeks[i].days = weeks[i].days.sort((a, b) => {
                if (a.dayIndex > b.dayIndex) {
                    return 1;
                } else if (a.dayIndex < b.dayIndex) {
                    return -1;
                }

                return 0;
            });
        }

        setGroupedWeeks(weeks);
        reset(existingPattern);
    }

    const getStartDay = async () => {
        const token = await authService.getAccessToken();

        crabFetch('Company/GetWorkingWeekStart', {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setWorkingWeekStart(data);
            }
        );
    }

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch('Shift/GetShiftPattern?id=' + shiftPatternId + '&userId=' + userId, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: ShiftPatternViewModel) => {
                data.startWeek = 0;

                data.weeks = data.weeks.map(week => {
                    week.days = week.days.map(day => {
                        day.startTime = dayjs(day.startTime);
                        day.endTime = dayjs(day.endTime);
                        return day;
                    });
                    return week;
                });

                setExistingPattern(data);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        form.weeks = [...groupedWeeks];
        if (form.weeks.length === 0) {
            show('error', `You must assign this user a shift`);
            return;
        }

        if (form.startWeek === 0 )
            form.startWeek = null;

        for (var i = 0; i < form.weeks.length; i++) {
            for (var j = 0; j < 7; j++) {
                var day = form.weeks[i].days[j];

                if (day === null || day === undefined) {
                    console.log("Day not found");
                    continue;
                }

                if (!day.startTime && !day.endTime) {
                    continue;
                }

                if ((!dayjs(day.startTime).isValid() || !dayjs(day.endTime).isValid())
                    && (day.startTime?.toString() !== day.endTime?.toString())) {
                    setCurrentError(`Shift Times are invalid`);
                    setShowAlert(true);
                    return;
                } else {
                    // daylight savings offset
                    const start = new Date(day.startTime?.toString()!);
                    day.startTime = start;

                    const end = new Date(day.endTime?.toString()!);
                    day.endTime = end;
                }

                form.weeks[i].days[j] = day;
            }
        }

        setSubmitting(true);

        crabFetch(`Shift/ModifyShiftPattern${userId ? '?userId=' + userId : ''}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                setSubmitting(false);

                if (data.length > 0) {
                    setCurrentError(data);
                    setShowAlert(true);
                }
                else {
                    closeDialog(true);
                    show('success', `Successfully modified shift`);
                    setShowAlert(false);
                }
            },
            (error: any) => {
                setSubmitting(false);
                show('error', error);
            }
        );
    }

    const addWeek = () => {
        const newWeeks = [...groupedWeeks];
        const maxWeek = newWeeks.length > 0 ? Math.max(...newWeeks.map(m => m.weekIndex)) : 0;

        newWeeks.push({
            weekIndex: maxWeek + 1,
            days: [
                { id: null, dayIndex: 0, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 1, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 2, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 3, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 4, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 5, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
                { id: null, dayIndex: 6, weekIndex: maxWeek + 1, active: false, startTime: null, endTime: null },
            ],
            totalWorkingHour: 0
        });

        setGroupedWeeks(newWeeks);

        if (newWeeks.length <= 1)
            formContext.setValue('startWeek', 0);
    }

    const updateWeek = (week: ShiftWeekViewModel) => {
        const newWeeks = [...groupedWeeks];
        const index = newWeeks.findIndex(f => f.weekIndex === week.weekIndex);

        newWeeks[index] = week;

        setGroupedWeeks(newWeeks);
    }

    const removeWeek = (week: number) => {
        const weeks = [...groupedWeeks];
        const newWeeks = weeks.filter((f => f.weekIndex !== week));

        setGroupedWeeks(newWeeks);

        if (newWeeks.length <= 1)
            formContext.setValue('startWeek', 0);
    }

    const closeDialog = (refresh: boolean) => {
        setWeeks();

        if (refresh) {
            setGroupedWeeks([{
                weekIndex: 1,
                days: [
                    { id: null, dayIndex: 0, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 1, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 2, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 3, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 4, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 5, weekIndex: 1, active: false, startTime: null, endTime: null },
                    { id: null, dayIndex: 6, weekIndex: 1, active: false, startTime: null, endTime: null },
                ],
                totalWorkingHour: 0
            }]);
        }
        onClose(refresh);
        reset(new ShiftPatternViewModel());
    }

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(true)}
            fullWidth
            maxWidth="xl"
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>{shiftPatternId ? 'Modify' : 'Create New'} Shift Pattern</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant="h2" gutterBottom>Name:</Typography>
                            <TextFieldElement autoComplete='off' name="name" required fullWidth size="small" />
                        </Grid>
                        {showDefault &&
                            <Grid item xs={4}>
                                <Typography variant="h2" gutterBottom>Default Shift:</Typography>
                                <CheckboxElement name="default" />
                            </Grid>
                        }
                        {(userId && groupedWeeks.length > 1) &&
                            <Grid item xs={4}>
                                <Typography variant="h2" gutterBottom>Rotation Starting Week:</Typography>
                                <SelectElement name="startWeek" required fullWidth size="small" options={groupedWeeks.map(item => { return { id: item.weekIndex, label: item.weekIndex } })} />
                            </Grid>
                        }
                        {(groupedWeeks && existingPattern.workingWeekStart !== undefined && groupedWeeks.length > 0) && groupedWeeks.map((week, index) =>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'} key={'shiftWeek' + index}>
                                <Grid item xs={12}>
                                    <ShiftPatternWeek week={week} remove={removeWeek} update={updateWeek} workingWeekStart={workingWeekStart} />
                                </Grid>
                            </LocalizationProvider>
                        )}
                        {showAlert &&
                            <Grid item xs={12}>
                                <Alert severity="warning">{currentError}</Alert>
                            </Grid>
                        }
                        <Grid item>
                            <Button variant="contained" fullWidth color="primary" onClick={addWeek}>Add Week</Button>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <LoadingButton variant="contained" type="submit" fullWidth color="primary" loading={submitting}>Save Changes</LoadingButton>
                        <Button variant="outlined" onClick={() => closeDialog(true)} color="error" fullWidth disabled={submitting}>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}