/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { Months, WeekDayOptions } from '../Utilities/CalendarEnums';
import IconButton from '@mui/material/IconButton';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import MonthlyEventsViewModel from './Viewmodels/MonthlyEventsViewModel';
import MonthViewRow from './MonthViewRow';
import CreateUserEvent from './Events/CreateUserEvent';
import ModifyUserEvent from './Events/ModifyUserEvent';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import Button from '@mui/material/Button';
import EditTeam from '../Teams/EditTeam';
import { lighten } from '@mui/material/styles';
import styled from '@emotion/styled';
import { tableClasses } from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';

const HoverHeaderTable = styled(ResponsiveTable)(() => ({
    [`&.${tableClasses.root} thead th:not(:empty):hover #hoverElement`]: {
        content: '""',
        height: '10000px!important',
        left: 0,
        position: 'absolute',
        top: 'calc(0px + 100%)',
        marginLeft: '-1px',
        width: 'calc(2px + 100%)!important',
        zIndex: -100,
    },
    [`&.${tableClasses.root} th:hover #hoverElement`]: {
        border: `1px solid ${lighten('#456FEC', 0.25)}`
    },
    overflowY: 'clip',
}));

interface IProps {
    team: number;
    dateFilter: Date;
    changeTeamFilter: (team: number) => void;
    changeDateFilter: (date: Date) => void;
    staff: MonthlyEventsViewModel[];
    setStaff: (staff: MonthlyEventsViewModel[]) => void;
    getStaff: (monthFilter: any) => void;
    getYourRequests?: (dropdown: string) => void;
    loading: boolean;
    setLoading: (bool: boolean) => void;
    viewRequest?: (request: number | null, date: Date | null, teamId: number | null) => void;
    selectedRequest?: number | null;
    getStaffRequests?: () => void;
}

interface Selection {
    userId: string;
    start: number;
    end: number;
}

export interface ModifyEvent {
    userId: string;
    eventId: number;
}

export default function MonthView(props: IProps) {
    const { team, dateFilter, changeDateFilter, changeTeamFilter, staff, getStaff, setStaff, getYourRequests, getStaffRequests, loading, setLoading, viewRequest } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [role, setRole] = React.useState('');
    const [viewingUserId, setViewingUserId] = React.useState('');
    const [teams, setTeams] = React.useState<DropdownViewModel[]>([]);
    const [yearOptions, setyearOptions] = React.useState<number[] | null>(null);
    const [eventSelection, setEventSelection] = React.useState<Selection | null>(null);
    const [modifyEvent, setModifyEvent] = React.useState<ModifyEvent | null>(null);
    const [weekStart, setWeekStart] = React.useState(1);
    const [managerOfId, setManagerOfId] = React.useState(0);
    const [monthFilter, setMonthFilter] = React.useState(new Date());
    const [fullsize, setFullsize] = React.useState(false);
    const [openTeam, setOpenTeam] = React.useState(false);

    React.useEffect(() => {
        getData();

        const today = new Date();
        const yearsArray: number[] = [];

        for (let i = today.getFullYear() - 2; i <= today.getFullYear() + 2; i++) {
            yearsArray.push(i);
        }
        setyearOptions(yearsArray);
    }, []);

    React.useEffect(() => {
        const day = dateFilter.getDate();

        if (dateFilter.getMonth() !== monthFilter.getMonth()) {
            setMonthFilter(new Date(dateFilter.getFullYear(), dateFilter.getMonth(), 1, 12));
        }

        const element = document.getElementById("dayHead" + day);

        if (element) {
            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });
        }
    }, [dateFilter, monthFilter]);

    React.useEffect(() => {
        if (team.toString().length > 0) {
            getStaff(monthFilter);
            setLoading(true);
        }
    }, [team, monthFilter]);

    React.useEffect(() => {
        if (staff && staff.length > 0 && loading) {
            getStaffShifts();
        }
    }, [staff]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user: any = await authService.getUser();
        setRole(user.role);
        setViewingUserId(user.sub);

        crabFetch(`Team/GetTeamDropdown`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                data.unshift({ id: 0, label: 'All Teams' });
                setTeams(data);
            }
        );

        crabFetch(`Company/GetWorkingWeekStart`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: number) => {
                setWeekStart(data);
            }
        );

        if (user.role === 'Staff Member') {

            crabFetch(`Staff/GetManagerOfId `, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data) => {
                    if (data != null) {
                        setManagerOfId(data);
                    }
                }
            );
        }
    }

    const getStaffShifts = async () => {
        Promise.all(staff.map(item => getShifts(item.id)))
            .then((values) => {
                let staffCopy = [...staff];

                staffCopy = staffCopy.map(item => {
                    const staffShifts = values.find(f => f.userId === item.id);
                    item.shifts = staffShifts!.shifts;
                    return item;
                });

                setStaff(staffCopy);

                setLoading(false);
            });
    }

    const getShifts = async (userId: string) => {
        const token = await authService.getAccessToken();

        const result = await crabFetch(`Event/GetNonWorkingDays?dateFilter=${monthFilter.toISOString()}&userId=${userId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON, undefined, undefined, true);

        return { userId: userId, shifts: result };
    }

    const onTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(e.target.valueAsNumber) ?
            e.target.value :
            e.target.valueAsNumber;
        if (changeDateFilter !== undefined) {
            changeTeamFilter(value as number);
        }
    }

    const onAddEvent = (userId: string, start: number, end: number) => {
        setEventSelection({ userId, start, end });
    }

    const closeAddEvent = (refresh: boolean) => {
        if (refresh) {
            getStaff(monthFilter);
            setLoading(true);

            if (getYourRequests) {
                getYourRequests('0');
            }
            if (getStaffRequests) {
                getStaffRequests();
            }

            const today = new Date();

            if (today.getMonth() === monthFilter.getMonth() && today.getFullYear() === monthFilter.getFullYear()) {
                changeDateFilter(new Date(monthFilter.getFullYear(), monthFilter.getMonth(), today.getDate()));
            } else {
                changeDateFilter(new Date(monthFilter.getFullYear(), monthFilter.getMonth(), 1));
            }
        }

        setEventSelection(null);
        setModifyEvent(null);
    }

    const closeTeamEdit = (refresh: boolean) => {
        if (refresh)
            getStaff(monthFilter);
        setOpenTeam(false);
    }

    const daysInMonth = React.useMemo(() => {
        const date = new Date(monthFilter.getFullYear(), monthFilter.getMonth() + 1, 0);

        return Array.from({ length: date.getDate() }, (v, i) => {
            return {
                day: i + 1,
                dayOfWeek: WeekDayOptions[new Date(monthFilter.getFullYear(), monthFilter.getMonth(), i + 1).getDay()],
                dayWeekNum: new Date(monthFilter.getFullYear(), monthFilter.getMonth(), i + 1).getDay()
            }
        });
    }, [monthFilter]);

    const onMonthChange = (forward: boolean) => {
        if (forward) {
            const newDate = new Date(monthFilter.setMonth(monthFilter.getMonth() + 1));
            setMonthFilter(newDate);

            const today = new Date();

            if (today.getMonth() === newDate.getMonth() && today.getFullYear() === newDate.getFullYear()) {
                changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), today.getDate()));
            } else {
                changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
            }
        } else {
            const newDate = new Date(monthFilter.setMonth(monthFilter.getMonth() - 1));
            setMonthFilter(newDate);

            const today = new Date();

            if (today.getMonth() === newDate.getMonth() && today.getFullYear() === newDate.getFullYear()) {
                changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), today.getDate()));
            } else {
                changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
            }
        }
    }
    const onMonthDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = new Date(monthFilter.setMonth(Number(e.target.value)));
        setMonthFilter(newDate);

        const today = new Date();

        if (today.getMonth() === newDate.getMonth() && today.getFullYear() === newDate.getFullYear()) {
            changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), today.getDate()));
        } else {
            changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
        }
    }

    const onYearDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = new Date(monthFilter.setFullYear(Number(e.target.value)));
        setMonthFilter(newDate);

        const today = new Date();

        if (today.getMonth() === newDate.getMonth() && today.getFullYear() === newDate.getFullYear()) {
            changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), today.getDate()));
        } else {
            changeDateFilter(new Date(newDate.getFullYear(), newDate.getMonth(), 1));
        }
    }

    const modifyingEvent = React.useMemo(() => {
        if (modifyEvent) {
            const staffMember = staff.find(f => f.id === modifyEvent?.userId);
            const staffEvent = staffMember?.events.find(f => f.leaveId === modifyEvent.eventId)!;

            if (staffMember && staffEvent?.status === 'Pending' && viewRequest) {
                viewRequest(modifyEvent.eventId, null, staffMember?.teamId);
                return null;
            }
            return staffEvent;
        }
        else
            return modifyEvent;
    }, [modifyEvent]);

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Grid spacing={2} container justifyContent="space-between" alignItems="center">
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    select
                                    value={team}
                                    onChange={onTeamChange}
                                    size='small'
                                >
                                    {teams.map(item =>
                                        <MenuItem key={'team' + item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="Previous Month" disabled={loading || !(yearOptions !== null && !(monthFilter.getFullYear() == yearOptions![0] && monthFilter.getMonth() == 0))} onClick={() => onMonthChange(false)}><NavigateBefore /></IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton aria-label="Next Month" disabled={loading || !(yearOptions !== null && !(monthFilter.getFullYear() == yearOptions![yearOptions!.length - 1] && monthFilter.getMonth() == 11))} onClick={() => onMonthChange(true)}><NavigateNext /></IconButton>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    select
                                    value={monthFilter.getMonth()}
                                    onChange={onMonthDropdownChange}
                                    size='small'
                                >
                                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(item =>
                                        <MenuItem key={Months[item] + item} value={item}>
                                            {Months[item]}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    select
                                    value={monthFilter.getFullYear()}
                                    onChange={onYearDropdownChange}
                                    size='small'
                                >
                                    {yearOptions && yearOptions.map(item =>
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    {((role === 'Company Admin' || role === 'Admin') && team !== 0) &&
                        <Grid item>
                            <Button onClick={() => setOpenTeam(true)} size='small' variant="outlined">Edit Team</Button>
                        </Grid>
                    }
                    {role !== "Admin" &&
                        <Grid item>
                            <Button size='small' variant="contained" onClick={() => setEventSelection({ userId: viewingUserId, start: 1, end: 1 })}>Make a Request</Button>
                        </Grid>
                    }
                    <Grid item>
                        <IconButton size="small" onClick={() => setFullsize(!fullsize)}>{fullsize ? <CloseFullscreenIcon /> : <OpenInFullIcon />}   </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <HoverHeaderTable
                        id='monthTable'
                        aria-labelledby="tableTitle"
                        aria-label="month's events view"
                        size="small"
                        sx={{
                            borderCollapse: 'separate',
                            tableLayout: 'fixed',
                            minWidth: `calc(150px + (37px * ${daysInMonth.length}))`,
                            maxWidth: `calc(275px + (37px * ${daysInMonth.length}))`,
                        }}
                        maxHeight={fullsize ? undefined : 350}
                    >
                        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1000 }}>
                            <TableRow>
                                <TableCell sx={{
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: 'white',
                                    maxWidth: 275,
                                    minWidth: 150,
                                    padding: 0,
                                    borderRight: `2px #B5ADC4 solid`,
                                    zIndex: 1000
                                }} />
                                {daysInMonth.map(item => {
                                    const style: any = dateFilter.getDate() === item.day ? {
                                        width: 37,
                                        padding: 0,
                                        cursor: 'pointer',
                                        border: '2px solid',
                                        borderColor: '#456FEC #456FEC #B5ADC4',
                                        borderBottomWidth: '1px',
                                        position: 'relative',
                                    } : {
                                        width: 37,
                                        padding: 0,
                                        cursor: 'pointer',
                                        borderLeft: `1px ${item.dayWeekNum === weekStart ? '#B8B8B8' : '#F2F4F8'} solid`,
                                        borderBottomWidth: '1px',
                                        position: 'relative',
                                        '&:hover': {
                                            border: '1px solid',
                                            borderColor: `${lighten('#456FEC', 0.25)} ${lighten('#456FEC', 0.25)} #B5ADC4`,
                                            borderBottomWidth: '1px'
                                        }
                                    };

                                    return <TableCell key={'dayHead' + item.day} id={'dayHead' + item.day} align="center" onClick={() => changeDateFilter(new Date(monthFilter.getFullYear(), monthFilter.getMonth(), item.day))}
                                        sx={style}
                                    >
                                        {item.day}<br />{item.dayOfWeek[0]}
                                        <div id="hoverElement" style={{ width: 0, height: 0, pointerEvents: 'none' }} />
                                    </TableCell>;
                                })}
                            </TableRow>
                        </TableHead>
                        {!loading &&
                            <TableBody>
                                {staff && teams && (teams.length > 0 || role === 'Staff Member') && staff.map((item, index) => {
                                    if (index === 0 || item.teamId !== staff[index - 1].teamId)
                                        return <MonthViewRow key={'staffRow' + item.id + index + (item.isManager ? 'manager' : '')} row={item} daysInMonth={daysInMonth} addEvent={onAddEvent} modifyEvent={setModifyEvent} teamName={(item.teamId !== 0 && item.teamId) ? teams.find(f => f.id === item.teamId)!.label as string : 'No Team'} monthFilter={monthFilter} selected={dateFilter} weekStart={weekStart} rowClickable={viewingUserId === item.id || managerOfId === item.teamId || role === 'Admin' || role === 'Company Admin'} />;
                                    else
                                        return <MonthViewRow key={'staffRow' + item.id + index} row={item} daysInMonth={daysInMonth} addEvent={onAddEvent} modifyEvent={setModifyEvent} monthFilter={monthFilter} selected={dateFilter} weekStart={weekStart} rowClickable={(viewingUserId === item.id) || managerOfId === item.teamId || role === 'Admin' || role === 'Company Admin'} />;
                                })}
                            </TableBody>
                        }
                        {loading &&
                            <TableBody>
                                <TableRow
                                    style={{
                                        height: 53 * 5,
                                        backgroundColor: 'white', color: 'white',
                                    }}
                                >
                                    <TableCell colSpan={(new Date(monthFilter.getFullYear(), monthFilter.getMonth() + 1, 0)).getDate() + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                </TableRow>
                            </TableBody>
                        }
                    </HoverHeaderTable>
                </TableContainer>
            </Grid>
            {eventSelection && <CreateUserEvent
                open={eventSelection !== null}
                userId={eventSelection?.userId}
                endDate={new Date(monthFilter.getFullYear(), monthFilter.getMonth(), eventSelection.end)}
                startDate={new Date(monthFilter.getFullYear(), monthFilter.getMonth(), eventSelection.start)}
                onClose={closeAddEvent}
                userStartDate={new Date(staff.find(f => f.id === eventSelection?.userId)?.startDate!)}
                userLeaveDate={staff.find(f => f.id === eventSelection?.userId)?.leaveDate}
            />
            }
            {modifyEvent && modifyingEvent &&
                <ModifyUserEvent
                    open={modifyEvent !== null}
                    eventId={modifyEvent.eventId}
                    onClose={closeAddEvent}
                    userStartDate={new Date(staff.find(f => f.id === modifyEvent?.userId)?.startDate!)}
                    userLeaveDate={staff.find(f => f.id === modifyEvent?.userId)?.leaveDate}
                />
            }
            <EditTeam open={openTeam} teamId={team} onClose={closeTeamEdit} />
        </Grid >
    );
}