import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import CompanyDetailsViewModel from './Viewmodels/CompanyDetailsViewModel';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/en-gb';
import Button from '@mui/material/Button';
import { monthDayValues, Months } from '../Utilities/CalendarEnums';
import  LoadingButton  from '@mui/lab/LoadingButton';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';
import Alert from '@mui/material/Alert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InvoiceDialog from './InvoiceDialog';

const dayOfWeekOptions = [
    { id: 0, label: 'Sunday' },
    { id: 1, label: 'Monday' },
    { id: 2, label: 'Tuesday' },
    { id: 3, label: 'Wednesday' },
    { id: 4, label: 'Thursday' },
    { id: 5, label: 'Friday' },
    { id: 6, label: 'Saturday' },
];

const ukCountries = [
    { id: 0, label: 'England' },
    { id: 1, label: 'Scotland' },
    { id: 2, label: 'Wales' },
    { id: 3, label: 'Northern Ireland' },
];

export default function CompanyDetails() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [currentDetails, setCurrentDetails] = React.useState(new CompanyDetailsViewModel());
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());

    const formContext = useForm<CompanyDetailsViewModel>({
        defaultValues: new CompanyDetailsViewModel()
    });
    const { reset } = formContext;

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        reset(currentDetails)
    }, [currentDetails]);

    const closeEdit = (refresh: boolean) => {
        if (refresh) getData();
    }

    const goToUpgrade = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToUpgrade', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }

                setStripeLoading(false);
            }
        );
    }

    const goToBillingPortal = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToBillingPortal', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }

                setStripeLoading(false);
            }
        );
    }

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch('Company/GetCompanyDetails', {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setCurrentDetails(data);
            }
        );

        crabFetch(`Billing/GetSubscriptionStatus`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setSubscriptionStatus(data);
            }
        );

    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();

        crabFetch('Company/UpdateCompanyDetails', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully updated company details");
                    closeEdit(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const watchFromMonth = formContext.watch('holidayFromMonth');

    const monthList = monthDayValues.map((item) => {
        return { id: item.month, label: Months[item.month - 1].toString() };
    });

    const fromDayOptions = React.useMemo(() => {
        if (watchFromMonth) {
            return Array.from({ length: monthDayValues[watchFromMonth - 1].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
        } else
            return Array.from({ length: monthDayValues[0].days }, (v, i) => { return { id: i + 1, label: i + 1 } });
    }, [watchFromMonth]);

    return (
        <>
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">Details</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" >Save Changes</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                            <Grid container spacing={2}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12} sm={'auto'}>
                                        <InputLabel htmlFor="name" shrink>Name</InputLabel>
                                        <TextFieldElement autoComplete='off' name="name" required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={'auto'}>
                                        <InputLabel htmlFor="country" shrink>Country</InputLabel>
                                        {ukCountries.length > 0 && <SelectElement options={ukCountries} name="country"></SelectElement>}
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item xs={12} sm={'auto'}>
                                        <InputLabel htmlFor="workingWeekStart" shrink>Working Week Start Day</InputLabel>
                                        <SelectElement name="workingWeekStart" required fullWidth options={dayOfWeekOptions} />
                                    </Grid>
                                    <Grid item xs={12} sm={'auto'}>
                                        <InputLabel htmlFor="holidayFrom" shrink>Holiday From</InputLabel>
                                        <SelectElement name="holidayFromMonth" required options={monthList} sx={{ pr: 2 }} />
                                        {fromDayOptions.length > 0 && <SelectElement name="holidayFromDay" required options={fromDayOptions} />}
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={2}>
                                    <Grid item>
                                        <InputLabel htmlFor="entitlement" shrink>Standard Full Time Entitlement</InputLabel>
                                        <TextFieldElement autoComplete='off' name="entitlement" required fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </FormContainer>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" mt={2}>
                <Grid item>
                    <Typography variant="h3">Subscription Details</Typography>
                </Grid>
                <Grid item>
                    <Button onClick={() => setOpen(true)}>Pay via Invoice</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {subscriptionStatus.status === "trialing" && !subscriptionStatus.hasPaymentMethod &&
                    <Grid item>
                        <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>}>You have {subscriptionStatus.trialDaysRemaining} days left of your free trial.</Alert>
                    </Grid>
                }
                {subscriptionStatus.status === "trialing" && subscriptionStatus.hasPaymentMethod &&
                    <Grid item>
                        <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Manage Subscription</LoadingButton>}>You are subscribed. You will be charged when your free trial ends in {subscriptionStatus.trialDaysRemaining} days.</Alert>
                    </Grid>
                }
                {(subscriptionStatus.status !== "trialing" && subscriptionStatus.status !== "active") &&
                    <Grid item>
                        <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>}>Your subscription has expired. Upgrade now to keep using teamplanner.</Alert>
                    </Grid>
                }
                {subscriptionStatus.status === "active" &&
                    <>
                        <Grid item xs={12}>
                            <Alert variant="outlined" severity="info" action={<LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Manage Subscription</LoadingButton>}>You're using <b>{subscriptionStatus.usedSeats} of {subscriptionStatus.seatsInSubscription}</b> paid seats. Add more seats or manage your subscription here: </Alert>
                        </Grid>
                        <Grid item>
                            Update your payment method here:
                        </Grid>
                        <Grid item>
                            <LoadingButton size="small" endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToUpgrade}>Manage Payment Methods</LoadingButton>
                        </Grid>
                    </>
                }
            </Grid>
            <InvoiceDialog open={open} onClose={setOpen} />
        </>
    );
}
