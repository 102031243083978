import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../api-authorization/AuthorizeService';
import StaffManagerList from '../Staff/StaffManagerList';
import YourCalendarRequests from './YourCalendarRequests';
import HolidayAllowance from '../Staff/HolidayAllowance';
import ToilAllowance from '../Staff/ToilAllowance';
import Paper from '@mui/material/Paper';
import MonthView from './MonthView';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import MemberShiftManagement from '../Staff/MemberShiftManagement';
import Typography from '@mui/material/Typography';
import StaffDetails from '../Staff/StaffDetails';
import MonthlyEventsViewModel from './Viewmodels/MonthlyEventsViewModel';
import EventRequestTableViewModel from '../Event/Viewmodels/EventRequestTableViewModel';
import ICalLinkDialog from './iCalLinkDialog';
import Button from '@mui/material/Button';
import HolidayAllowanceViewModel from '../Staff/Viewmodels/HolidayAllowanceViewModel';
import ToilViewModel from '../Staff/Viewmodels/ToilViewModel';

export default function YourCalendar() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [userId, setUserId] = React.useState('');
    const [dateFilter, setDateFilter] = React.useState<Date>(new Date());
    const [team, setTeam] = React.useState<number | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [staff, setStaff] = React.useState<MonthlyEventsViewModel[]>([]);
    const [requests, setRequests] = React.useState<EventRequestTableViewModel[]>([]);;
    const [holidayAllowance, setHolidayAllowance] = React.useState<HolidayAllowanceViewModel>(new HolidayAllowanceViewModel());
    const [toilAllowance, setToilAllowance] = React.useState<ToilViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [holidayLoading, setHolidayLoading] = React.useState(true);
    const [loadingStaff, setLoadingStaff] = React.useState(true);
    const [loadingToil, setLoadingToil] = React.useState(true);
    

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if (userId.length > 0) {
            getHolidayAllowance();
        }
    }, [dateFilter, userId]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUserId(user.sub);

        crabFetch(`Staff/GetMemberOfId`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: number) => {
                setTeam(data);
            }
        );
    }

    const getStaff = async (monthFilter: any) => {
        const token = await authService.getAccessToken();
        setLoadingStaff(true);

        crabFetch(`Event/GetMonthlyStaffEvents?dateFilter=${monthFilter.toISOString()}${team ? `&teamId=${team}` : ``}&getYourCalendar=${true}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: MonthlyEventsViewModel[]) => {
                setStaff(data);
                getToil();
            }
        );
    }

    const getRequests = async (dropdown: string) => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch(`Staff/YourCalendarRequests?status=${dropdown}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setRequests(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const getToil = async () => {
        const token = await authService.getAccessToken();
        crabFetch(`Staff/GetToilAllowance?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setToilAllowance(data);
                setLoadingToil(false);
            },
            (error: any) => {
                setLoadingToil(false);
            }
        );
    }

    const getHolidayAllowance = async () => {
        const token = await authService.getAccessToken();
        crabFetch(`Staff/GetHolidayAllowance?userId=${userId}&dateFilter=${dateFilter.toISOString()}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setHolidayAllowance(data);
                setHolidayLoading(false);
            },
            (error: any) => {
                setHolidayLoading(false);
            }
        );
    }
 
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} sx={{ mb: -1 }}>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Typography variant="h1">My Calendar</Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" size={"small"} onClick={() => setOpen(true)}>Connect to your calendar</Button>
                        </Grid>
                    </Grid>
                    {team !== null &&
                        <Grid item xs={12}>
                            <Paper>
                                <MonthView dateFilter={dateFilter} changeDateFilter={setDateFilter} team={team} changeTeamFilter={setTeam} staff={staff} setStaff={setStaff} getStaff={getStaff} getYourRequests={getRequests} loading={loadingStaff} setLoading={setLoadingStaff} />
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <MemberShiftManagement userId={userId} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h2" sx={{ mb: 1 }}>Your Requests</Typography>
                        <YourCalendarRequests getStaff={getStaff} requests={requests} getRequests={getRequests} loading={loading} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HolidayAllowance holidayAllowance={holidayAllowance} loading={holidayLoading} />
                    </Grid>
                    <Grid item xs={12}>
                        <ToilAllowance toilAllowance={toilAllowance} loading={loadingToil} />
                    </Grid>
                    <Grid item xs={12}>
                        <StaffDetails userId={userId} />
                    </Grid>
                    <Grid item xs={12} >
                        <StaffManagerList userId={userId} />
                    </Grid>
                </Grid>
            </Grid>
            <ICalLinkDialog open={open} onClose={setOpen}/>
        </Grid>
    );
}