import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import OnboardRow from './OnboardRow';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function OnboardStaffDialog(props: IProps) {
    const { open, onClose } = props;
    const [numOfRows, setNumOfRows] = React.useState(1);
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [companyEntitlement, setCompanyEntitlement] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Company/GetCompanyEntitlement`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: number) => {
                setCompanyEntitlement(data);
            }
        );
    }

    const addRow = () => {
        setLoading(true);
        setNumOfRows((prev) => prev + 1);
        setLoading(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Add Staff</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                    </Grid>
                    <Grid item xs={2.4}>
                        <InputLabel htmlFor="email" shrink>Email</InputLabel>
                    </Grid>
                    <Grid item xs={2.6}>
                        <InputLabel htmlFor="entitlement" shrink>Full Time Holiday Entitlement</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor="start" shrink>Start Date</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                        <InputLabel shrink></InputLabel>
                    </Grid>
                </Grid>
                {companyEntitlement !== 0 &&
                    <Grid container>
                        {Array.from({ length: numOfRows }, (_, index) => (
                            <OnboardRow key={ index } AddRow={addRow} entitlement={companyEntitlement} />
                        ))}
                    </Grid>
                }
                <DialogActions>
                    <Button disabled={loading} onClick={() => onClose(true)} variant="contained">Finish</Button>
                    <Button disabled={loading} onClick={() => onClose(false)} color="error">Skip for now</Button>
                    
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}