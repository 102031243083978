import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import ManagerListViewModel from './Viewmodels/ManagerListViewModel';

interface IProps {
    userId: string;
}

export default function StaffManagerList(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [managers, setManagers] = React.useState<ManagerListViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (userId.length > 0)
            getData();
    }, [userId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch(`Staff/GetManagers?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setManagers(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <>
            {managers.length > 0 &&
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h3">Your Managers:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            {(!loading && managers) && managers.map((row: ManagerListViewModel) => {
                                return (<Typography key={row.id}>{row.firstName} {row.lastName}</Typography>);
                            })
                            }
                        </Grid>
                    </Grid>
                </Paper>
            }
        </>
    );
}