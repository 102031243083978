import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ColouredRow from '../Utilities/ColouredRow';
import ToilViewModel from './Viewmodels/ToilViewModel';
import Pagination from '@mui/material/Pagination';

interface IProps {
    toilAllowance: ToilViewModel[];
    loading: boolean;
}

export default function ToilAllowance(props: IProps) {
    const { toilAllowance, loading } = props;
    const [page, setPage] = React.useState(0);

    return (
        <>
            <Typography sx={{ pb: 1 }} variant="h2">Toil Remaining</Typography>
            <Paper>
                <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid item xs={12}>
                        {!loading &&
                            <Grid container alignItems="center" justifyContent="space-evenly">
                                <Grid item>
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress sx={{ transform: 'rotate(0deg)' }} size={120} thickness={3} variant="determinate" value={100} />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="h1"
                                                component="div"
                                            >{toilAllowance.reduce((acc, toil) => acc + toil.toilHours, 0)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h2">Hours remaining</Typography>
                                    <Typography>(Or {(toilAllowance.reduce((acc, toil) => acc + toil.toilHours, 0) / 8)} Days)</Typography>
                                </Grid>
                            </Grid>
                        }
                        {loading &&
                            <CircularProgress size={120} thickness={3} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {toilAllowance.length > 0 &&
                            <Grid sx={{ pl: 1 }} container item spacing={2}>
                                <Typography variant='body1'>TOIL hours expire. See the breakdown below</Typography>
                            </Grid>
                        }
                        <Grid container spacing={1}>
                            {toilAllowance.slice(page * 3, page * 3 + 3).map((item, index) => {
                                return <Grid item xs={12}>
                                    <ColouredRow colour={'#456FEC'} >
                                        <Grid container spacing={2} >
                                            <Grid item xs={4}><Typography color='#456FEC'>{item.toilHours} Hours</Typography></Grid>
                                            <Grid item xs={8}>Expires on {new Date(item.expiryDate).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })}</Grid>
                                        </Grid>
                                    </ColouredRow>
                                </Grid>;
                            })}
                            {!loading && toilAllowance.length > 0 &&
                                <Grid container item justifyContent="flex-end">
                                    <Pagination count={Math.ceil(toilAllowance.length / 3)} siblingCount={0} page={page + 1} onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}