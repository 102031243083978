import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EventRequestTableViewModel from '../Event/Viewmodels/EventRequestTableViewModel';
import Button from '@mui/material/Button';
import * as Icons from "@mui/icons-material";

interface IProps {
    row: EventRequestTableViewModel;
    viewRequest: (id: EventRequestTableViewModel) => void;
}

export default function PendingRequests(props: IProps) {
    const { row, viewRequest } = props;

    const Icon = React.useMemo(() => {
        return Icons[row.icon] ?? <React.Fragment />;
    }, [row.icon]);

    return (
        <Grid item xs={12}>
            <Box sx={{
                borderLeft: `10px solid ${row.colour}`,
                backgroundColor: 'background.default',
                p: 2,
                borderRadius: '10px'
            }}>
                <Grid alignItems="center"
                    justifyContent="center" container>
                    <Grid item xs={2}>
                        <Icon style={{ color: row.colour }} />
                    </Grid>
                    <Grid item xs={3}>
                        {row.name}
                    </Grid>
                    <Grid item xs={3}>
                        {new Date(row.from).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" }) + ' to ' + new Date(row.to).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })}
                    </Grid>
                    <Grid item xs={2}>
                        {row.status}
                    </Grid>
                    <Grid item xs={2}><Button onClick={() => viewRequest(row)} size={'small'}>View Status</Button></Grid>
                </Grid>
            </Box>
            
        </Grid>
    );
}