import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import authService from '../components/api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import Grid from '@mui/material/Grid';
import PersonRounded from '@mui/icons-material/PersonRounded';
import DashboardRounded from '@mui/icons-material/DashboardRounded';
import PeopleRounded from '@mui/icons-material/PeopleRounded';
import SettingsRounded from '@mui/icons-material/SettingsRounded';
import ViewTimelineRounded from '@mui/icons-material/ViewTimelineRounded';
import GroupsRounded from '@mui/icons-material/GroupsRounded';
import EventNoteRounded from '@mui/icons-material/EventNoteRounded';
import BeachAccessRounded from '@mui/icons-material/BeachAccessRounded';
import BusinessRounded from '@mui/icons-material/BusinessRounded';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from './Contexts/AdminContext';
import Link from '@mui/material/Link';
import { ResponseType, TokenRefreshContext } from './Contexts/TokenRefreshContext';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import SubscriptionStatusViewModel from './Billing/Viewmodels/SubscriptionStatusViewModel';
import { AlertContext } from './Contexts/AlertContext';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Hidden from '@mui/material/Hidden';
import Backdrop from '@mui/material/Backdrop';

const drawerWidth = 240;

const styles = {
    menuItem: {
        justifyContent: 'flex-end'
    },
    logoutMenuItem: {
        justifyContent: 'flex-end',
        color: '#F34519'
    }
}

const StyledListItem = styled(ListItem)(
    ({ theme }) => `
            color: ${theme.palette.text.primary};

                :hover { color: ${theme.palette.primary.main} };

            & .MuiListItemText-primary {
                color: inherit !important;
            }

            & .MuiListItemIcon-root {
                color: inherit
            }

            & .MuiSvgIcon-root {
                color: inherit !important
            }

            & .MuiIconButton-root {
                color: inherit
            }
    `);

interface IProps extends ListItemProps {
    to: string;
    label: string;
    icon?: any;
}

function ListItemLink(props: IProps) {
    const { to, label, icon, ...other } = props;

    return (
        <StyledListItem>
            <ListItemButton sx={{ pl: 1.5 }} fullwidth component={RouterLink as any} to={to} {...other} disableGutters>
                {icon && <ListItemIcon sx={{ minWidth: 44 }}>{icon}</ListItemIcon>}
                <ListItemText primary={label} />
            </ListItemButton>
        </StyledListItem>
    );
}

export default function Navmenu() {
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { currCompany, changeCompany } = React.useContext(AdminContext);
    const [role, setRole] = React.useState('');
    const [authenticated, setAuthenticated] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [teamId, setTeamId] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const authSubscription = authService.subscribe(() => updateAuth());
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [isTouchScreen, setIsTouchScreen] = React.useState(false);

    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());

    React.useEffect(() => {
        updateAuth();


        return () => {
            authService.unsubscribe(authSubscription);
        }
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        updateAuth();
        testTouchScreen();
    }, []);

    const updateAuth = async () => {
        const isAuth = await authService.isAuthenticated();
        const user = await authService.getUser();
        if (user && user.sub) {
            setUserName(user ? user.name : '');
            setRole(user ? user.role : '');
        }
        setAuthenticated(isAuth);
    }

    React.useEffect(() => {
        if (authenticated) {
            getData();
        }
    }, [authenticated]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user: any = await authService.getUser();
        setRole(user.role);

        if (user.role === 'Staff Member') {
            crabFetch(`Team/GetTeamId`, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data: number) => {
                    setTeamId(data);
                }
            );
        }

        if (user.role === 'Company Admin') {
            crabFetch(`Billing/GetSubscriptionStatus`, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data: any) => {
                    setSubscriptionStatus(data);
                }
            );
        }
    }

    const goToCheckout = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);
        crabFetch('Billing/GoToBillingPortal', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }
            }
        );
    }

    const clickLogout = () => {
        handleClose();
        navigate(`${ApplicationPaths.LogOut}`, {
            state: {
                local: true
            }
        });
    }

    const backToAdmin = async () => {
        changeCompany(null);
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const sidebarContent = (
        <>
            <Toolbar />

            <Box sx={{ overflow: 'auto' }}>
                <List id="navmenu">
                    {authenticated &&
                        <>
                            <ListItemLink to='/' label='Dashboard' icon={<DashboardRounded fontSize="small" />} />

                            {(role === 'Staff Member' && teamId > 0) &&
                                <>
                                    <ListItemLink to='/Calendar' label='My Calendar' icon={<EventNoteRounded fontSize="small" />} />
                                    <ListItemLink to='/Staff' label='My Team Members' icon={<PeopleRounded fontSize="small" />} />
                                    <ListItemLink to='/Shifts' label='Shift Patterns' icon={<ViewTimelineRounded fontSize="small" />} />
                                </>
                            }
                            {(role === 'Admin' && currCompany === null) &&
                                <>
                                    <ListItemLink to='/AdminManagement' label='Admin Management' icon={<PeopleRounded fontSize="small" />} />
                                    <ListItemLink to='/UserManagement' label='User Management' icon={<PeopleRounded fontSize="small" />} />
                                </>
                            }
                            {(role === 'Company Admin' || (role === 'Admin' && currCompany !== null)) &&
                                <>
                                    {role !== 'Admin' &&
                                        <ListItemLink to='/Calendar' label='My Calendar' icon={<EventNoteRounded fontSize="small" />} />

                                    }
                                    <ListItemLink to='/Staff' label='My Staff Members' icon={<PeopleRounded fontSize="small" />} />
                                    <ListItemLink to='/Teams' label='My Teams' icon={<GroupsRounded fontSize="small" />} />
                                    <ListItemLink to='/Shifts' label='Shift Patterns' icon={<ViewTimelineRounded fontSize="small" />} />

                                    <ListItemLink to='/ManageHolidays' label='Company Events' icon={<BusinessRounded fontSize="small" />} />
                                    <ListItemLink to='/EventTypes' label='Event Types' icon={<BeachAccessRounded fontSize="small" />} />
                                    <ListItemLink to='/CompanySettings' label='Settings' icon={<SettingsRounded fontSize="small" />} />

                                </>
                            }
                        </>
                    }
                    {subscriptionStatus.status === "trialing" &&
                        <Alert sx={{ marginX: 1 }} variant="outlined" severity="info">You have {subscriptionStatus.trialDaysRemaining} days left of your free trial. <LoadingButton sx={{ mt: 1 }} fullWidth size="small" loading={stripeLoading} variant="contained" onClick={goToCheckout}>Upgrade now</LoadingButton></Alert>}
                </List>
            </Box>
            <Link title="Smashed Crab Software" href="https://www.smashedcrabsoftware.com/" target="_blank" textAlign="center"
                sx={{ mt: 'auto', pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >Built by <img style={{ paddingLeft: 8 }} alt="Smashed Crab Software" src="/crab.png" /></Link>

        </>
    );

    const testTouchScreen = () => {
        try {
            document.createEvent('TouchEvent');
            setIsTouchScreen(true);
        } catch (e) {
            setIsTouchScreen(false);
        }
    }

    return (
        <>
            {isTouchScreen &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <Grid container sx={{ m: 3 }}>
                        <Grid item>
                            <Typography align="center">We're not quite ready for mobiles and tablets yet. Please use a laptop or desktop.</Typography>
                        </Grid>
                    </Grid>
                </Backdrop>
            }
            <AppBar position="fixed" color="primary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: 0 }} elevation={0}>
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <RouterLink to="/" style={{ height: 34 }}><img src="logo.png" alt="TeamPlanner" height="34" /></RouterLink>
                    <Alert sx={{ ml: 2 }} severity="info">Teamplanner is currently in beta, please report any issues to contact@theteamplanner.com</Alert>
                    <div style={{ flexGrow: 1 }} />
                    {currCompany != null &&
                        <Button sx={{ marginRight: 1 }} variant="outlined" color="inherit" size="small" onClick={backToAdmin}>Back to Admin</Button>
                    }
                    <Button disabled={!authenticated} color="inherit" size="large" aria-label="open account menu" endIcon={<PersonRounded />} onClick={handleClick}>
                        <Grid container style={{ textAlign: "right", paddingRight: 8 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{userName}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">{role}</Typography>
                            </Grid>
                        </Grid>
                    </Button>
                </Toolbar>
            </AppBar >
            {
                (role !== 'Staff Member' || (role === 'Staff Member' && teamId > 0)) &&
                <>
                    <Drawer
                        container={window.document.body}
                        variant="temporary"
                        open={mobileOpen}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', border: 'none', padding: 0 },
                        }}
                    >
                        {sidebarContent}
                    </Drawer>
                    <Hidden mdDown>
                        <Drawer
                            variant="permanent"
                            sx={{
                                width: drawerWidth,
                                flexShrink: 0,
                                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', border: 'none', padding: 0 },
                            }}
                            open
                        >
                            {sidebarContent}
                        </Drawer>
                    </Hidden>
                </>
            }
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}

                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={clickLogout} sx={styles.logoutMenuItem}>Logout</MenuItem>
            </Menu>
        </>
    );

}
