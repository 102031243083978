import * as React from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloudIcon from '@mui/icons-material/CloudUpload';
import Chip from "@mui/material/Chip";

interface IProps {
    onDrop: (files: File | null) => void;
    onDelete: (index: number) => void;
    files: File | null;
}

const boxStyle = [{
    display: 'flex',
    p: 2,
    bgcolor: 'grey.200',
    width: '100%',
    height: '100%',
    border: '2px dashed grey',
    borderColor: 'grey.500',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
},
{
    '&:hover': {
        bgcolor: 'grey.300',
    },
}];

export default function FileUpload(props: IProps) {
    const { onDrop, onDelete, files } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {

            if (event.target.files && event.target.files.item(0))
                onDrop(event.target.files.item(0));
        }
    }

    const dragEvents = {
        onDragEnter: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragLeave: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragOver: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
        },
        onDrop: (event: React.DragEvent<HTMLElement>) => {
            event.stopPropagation();
            event.preventDefault();
            onDrop(event.dataTransfer.files.item(0));
        }
    }
    return (
        <React.Fragment>
            <input
                onChange={handleChange}
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
            />
            <label htmlFor="file-upload" {...dragEvents} >
                <Box sx={boxStyle}>
                    <Stack alignItems="center">
                        <CloudIcon color="action" />
                        <Typography>Click or Drag & Drop to Upload Files</Typography>
                    </Stack>
                </Box>
            </label>
            <Box>
                {files && <Chip label={files.name} onDelete={() => onDelete(0)} />}
            </Box>
        </React.Fragment>
    );
}