import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { CheckboxElement, DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CompanyEventViewModel from './Viewmodels/CompanyEventViewModel';
import EventTypeDropdownViewModel from '../Event/Viewmodels/EventTypeDropdownViewModel';
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from 'dayjs';
import Alert from '@mui/material/Alert';
import { TimeField } from '@mui/x-date-pickers/TimeField';

interface IProps {
	open: boolean;
	onClose: (refresh: boolean) => void;
}

export default function CreateCompanyEvent(props: IProps) {
	const { open, onClose } = props;
	const { crabFetch } = React.useContext(TokenRefreshContext);
	const { show } = React.useContext(AlertContext);
	const [eventTypes, setEventTypes] = React.useState<EventTypeDropdownViewModel[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [firstStartTime, setFirstStartTime] = React.useState<Dayjs | null>(null);
	const [firstEndTime, setFirstEndTime] = React.useState<Dayjs | null>(null);
	const [lastStartTime, setLastStartTime] = React.useState<Dayjs | null>(null);
	const [lastEndTime, setLastEndTime] = React.useState<Dayjs | null>(null);

	const formContext = useForm({
		defaultValues: new CompanyEventViewModel()
	});
	const { reset, setValue, watch } = formContext;
	const watchStartDate = watch('start', dayjs(new Date()));
	const watchEndDate = watch('end', dayjs(new Date()));
	const watchStartAllDay = watch('fullStartDay', true);
	const watchEndAllDay = watch('fullEndDay', true);

	React.useEffect(() => {
		getData();
	}, []);

	React.useEffect(() => {
		if (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') < 12) {
			if (!watchStartAllDay && firstStartTime && firstEndTime && firstEndTime < firstStartTime) {
				setFirstEndTime(firstStartTime.add(1, 'h'));
			}

			if (!watchEndAllDay && lastStartTime && lastEndTime && lastEndTime < lastStartTime) {
				setLastEndTime(lastStartTime.add(1, 'h'));
			}
		}
	}, [firstStartTime, firstEndTime, lastStartTime, lastEndTime]);

	React.useEffect(() => {
		if (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') < 12) {
			setValue("fullEndDay", true);
		}
	}, [watchEndDate, watchStartDate]);

	const getData = async () => {
		const token = await authService.getAccessToken();

		crabFetch('Event/GetEventTypeDropdown', {
			method: 'GET',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
		}, ResponseType.JSON,
			(data: any) => {
				setEventTypes(data);
			}
		);
	}

	const submit = async (formData: any) => {
		const token = await authService.getAccessToken();
		setLoading(true);

		const form = { ...formData };

		if (form.start) {
			const startDate = new Date(form.start);
			const startEnd = new Date(form.start);

			startDate.setHours(12);
			startEnd.setHours(12);

			if (!form.fullStartDay && firstStartTime) {
				startDate.setHours(firstStartTime!.hour());
				startDate.setMinutes(firstStartTime!.minute());
			}

			if (!form.fullStartDay && firstEndTime) {
				startEnd.setHours(firstEndTime!.hour());
				startEnd.setMinutes(firstEndTime!.minute());
			}

			form.start = startDate;
			form.startEndTime = startEnd;
		}

		if (form.end) {
			const endDate = new Date(form.end);
			const endStart = new Date(form.end);
			endDate.setHours(12);
			endStart.setHours(12);

			if (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') < 12 && !form.fullStartDay) {
				endStart.setHours(firstStartTime!.hour());
				endStart.setMinutes(firstStartTime!.minute());

				endDate.setHours(firstEndTime!.hour());
				endDate.setMinutes(firstEndTime!.minute());
			}
			else {
				if (!form.fullEndDay && lastStartTime) {
					endStart.setHours(lastStartTime!.hour());
					endStart.setMinutes(lastStartTime!.minute());
				}

				if (!form.fullEndDay && lastEndTime) {
					endDate.setHours(lastEndTime!.hour());
					endDate.setMinutes(lastEndTime!.minute());
				}
			}

			form.end = endDate;
			form.endStartTime = endStart;
		}

		// Prevent submitting if not full days and no times entered
		if (!watchStartAllDay) {
			if (!firstEndTime?.isValid() || !firstStartTime?.isValid()) {
				setLoading(false);
				show('error', "Start and end times are required.");

				return;
			}
		}
		if (!watchEndAllDay && (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') > 12)) {
			if (!lastEndTime?.isValid() || !lastStartTime?.isValid()) {
				setLoading(false);
				show('error', "Start and end times are required.");

				return;
			}
		}

		crabFetch('Event/CreateCompanyEvent', {
			method: 'POST',
			headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
			body: JSON.stringify(form)
		}, ResponseType.Text,
			(data: any) => {
				if (data.length > 0) show('error', data);
				else {
					show('success', "Successfully created new company event");
					closeDialog(true);
					setLoading(false);
				}
			},
			(error: any) => {
				show('error', error);
				setLoading(false);
			}
		);
	}

	const closeDialog = (refresh: boolean) => {
		reset(new CompanyEventViewModel());
		setFirstStartTime(null);
		setFirstEndTime(null);
		setLastStartTime(null);
		setLastEndTime(null);
		onClose(refresh);
	}

	return (
		<Dialog
			open={open}
			onClose={() => closeDialog(false)}
			fullWidth
		>
			<FormContainer
				formContext={formContext}
				onSuccess={submit}
			>
				<DialogTitle>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>Create Company Event</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Alert severity="warning">This event will override any existing company events within the date range. If this event starts or ends within an existing event, then the original event will be cut short instead of being overridden.</Alert>
							</Grid>
							<Grid item xs={12}>
								<InputLabel htmlFor="Name" shrink>Name</InputLabel>
								<TextFieldElement autoComplete='off' name="name" fullWidth size="small" />
							</Grid>
							<Grid item xs={12}>
								<InputLabel htmlFor="eventType" shrink>Request Type</InputLabel>
								<SelectElement name="eventType" required labelKey="name" options={eventTypes} fullWidth size="small" />
							</Grid>
							<Grid item xs={12}>
								<InputLabel htmlFor="start" shrink>Start Date</InputLabel>
								<DatePickerElement onChange={(e) => setValue("end", e)} name="start" required inputProps={{ fullWidth: true, size: "small" }} />
							</Grid>
							{!watchStartAllDay &&
								<>
									<Grid item xs={12} md={6}>
										<InputLabel htmlFor="startfrom" shrink>From</InputLabel>
										<TimeField fullWidth size="small" format="HH:mm" value={firstStartTime} onChange={(newValue: Dayjs | null) => setFirstStartTime(newValue)} />
									</Grid>
									<Grid item xs={12} md={6}>
										<InputLabel htmlFor="startto" shrink>To</InputLabel>
										<TimeField fullWidth size="small" format="HH:mm" value={firstEndTime} onChange={(newValue: Dayjs | null) => setFirstEndTime(newValue)} />
									</Grid>
								</>
							}
							<Grid item xs={12}>
								<CheckboxElement name="fullStartDay" label="All Day" labelProps={{ labelPlacement: "start" }} />
							</Grid>
							<Grid item xs={12}>
								<InputLabel htmlFor="end" shrink>End Date</InputLabel>
								<DatePickerElement minDate={watchStartDate} onChange={(e) => setValue("end", e)} name="end" required inputProps={{ fullWidth: true, size: "small" }} />
							</Grid>
							{!watchEndAllDay &&
								<>
									<Grid item xs={12} md>
										<InputLabel htmlFor="endfrom" shrink>From</InputLabel>
										<TimeField fullWidth size="small" format="HH:mm" value={lastStartTime} onChange={(newValue: Dayjs | null) => setLastStartTime(newValue)} />
									</Grid>
									<Grid item xs={12} md>
										<InputLabel htmlFor="endto" shrink>To</InputLabel>
										<TimeField fullWidth size="small" format="HH:mm" value={lastEndTime} onChange={(newValue: Dayjs | null) => setLastEndTime(newValue)} />
									</Grid>
								</>
							}
							{dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') > 12 &&
								<Grid item xs={12}>
									<CheckboxElement name="fullEndDay" label="All Day" labelProps={{ labelPlacement: "start" }} />
								</Grid>
							}
						</Grid>
					</LocalizationProvider>
					<DialogActions>
						<Button disabled={loading} variant="contained" type="submit" fullWidth>Create</Button>
						<Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
					</DialogActions>
				</DialogContent>
			</FormContainer>
		</Dialog>
	);
}