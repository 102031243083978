import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StaffTableViewModel from './Viewmodels/StaffTableViewModel';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import authService from '../api-authorization/AuthorizeService';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import MoreVert from '@mui/icons-material/MoreVert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import CloseDialogConfirmation from '../Utilities/CloseDialogConfimation';

interface IProps {
    row: StaffTableViewModel;
    refresh: (active: boolean) => void;
}

export default function StaffTableRow(props: IProps) {
    const navigate = useNavigate();
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const [showAdminControls, setShowAdminControls] = React.useState(false);
    const [remainingEntitlement, setRemainingEntitlement] = React.useState<null | number>();
    const [openConfirmation, setopenConfirmation] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            const user = await authService.getUser();
            setShowAdminControls(user.role !== "Staff Member");
        })();
        getData();
    }, []);


    const profile = () => {
        navigate(
            '/Staff/Management',
            {
                state: {
                    userId: row.id
                }
            }
        );
    }

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch(`Staff/GetRemainingEntitlement?userId=${row.id}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            priority: "low",
        }, ResponseType.Text,
            (data: any) => {
                setLoading(false);
                if (!data.includes('Exception')) {
                    setRemainingEntitlement(data);
                }
            },
            () => {
                setLoading(false);
            }
        );
    }

    const reset = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`User/ResetPassword?userId=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent reset Email`);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const confirm = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`User/ResendEmailConfirmation?userId=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent confimation Email`);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.status ? 'UnArchiveUser' : 'ArchiveUser';

        crabFetch(`User/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.status ? 're-enabled' : 'archived'} User`);
                    refresh(true);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }


    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const onCloseConfimation = (bool: boolean) => {
        setopenConfirmation(bool)
        if (bool) {
            toggleArchive();
        }
    };


    return (
        <TableRow>
            <TableCell>
                <CardHeader
                    avatar={
                        <Avatar {...stringAvatar(row.name)} />
                    }
                    title={row.name}
                    subheader={row.role}
                    sx={{ m: -2 }}
                />
            </TableCell>
            <TableCell>{row.memberOf}</TableCell>
            <TableCell>
                {!remainingEntitlement && loading && <Skeleton />}
                {!remainingEntitlement && !loading && ""}
                {remainingEntitlement && `${remainingEntitlement} days`}
            </TableCell>
            <TableCell>{row.status ? "Inactive" : !row.confirmedEmail ? 'Pending Invite' : "Active"}</TableCell>
            <TableCell align='right'>
                <Grid container spacing={1} justifyContent='flex-end' >
                    <Grid>
                        <Button size='small' disabled={loading} variant={'contained'} onClick={profile}>View</Button>
                    </Grid>
                    {showAdminControls &&
                        <Grid>
                            <ButtonGroup variant={"text"} ref={anchorRef} aria-label="User actions dropdown">
                                <Button disabled={loading} onClick={handleToggle}>
                                    <MoreVert />
                                </Button>
                            </ButtonGroup>
                            <Popper
                                sx={{
                                    zIndex: 100000001,
                                }}
                                open={open}
                                anchorEl={anchorRef.current}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    <MenuItem
                                                        onClick={() => setopenConfirmation(true)}
                                                        aria-label={row.status ? "Activate" : "Deactivate"}
                                                        disabled={loading}
                                                    >
                                                        {row.status ? "Reactivate" : "Remove"}
                                                    </MenuItem>
                                                    {row.confirmedEmail &&
                                                        <MenuItem
                                                            onClick={reset}
                                                            aria-label='reset password'
                                                            disabled={loading}
                                                        >
                                                            Reset Password
                                                        </MenuItem>
                                                    }
                                                    {!row.confirmedEmail &&
                                                        <MenuItem
                                                            onClick={confirm}
                                                            aria-label='Send confirmation email'
                                                            disabled={loading}

                                                        >
                                                            Re-send Confimation
                                                        </MenuItem>
                                                    }

                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                    }
                </Grid>
            </TableCell>

            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'Are you sure you want to ${row.status ? "Activate" : "Deactivate"} this user?'} open={openConfirmation} onClose={onCloseConfimation} />
        </TableRow>
    );
}

function stringAvatar(name: string) {
    var words = name.split(' ').filter((item: string) => item !== ' ');
    var initals = words[0][0].toUpperCase() + words[1][0].toUpperCase();

    return {
        sx: {
            bgcolor: '#BDBDBD',
        },
        children: initals,
    };
}