import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement, CheckboxElement, useForm, DatePickerElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertContext } from '../Contexts/AlertContext';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import MemberViewModel from './Viewmodels/MemberViewModel';
import LoadingButton from '@mui/lab/LoadingButton';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId?: string;
    user: MemberViewModel;
}

export default function EditStaff(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, userId, user } = props;
    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState<DropdownViewModel[]>([]);
    const [team, setTeams] = React.useState<DropdownViewModel[]>([]);
    const [userRole, setUserRole] = React.useState('');

    React.useEffect(() => {
        getData();
    }, []);

    React.useEffect(() => {
        if (user) {
            const editUser = { ...user };
            editUser.startDate = editUser.startDate ? dayjs(editUser.startDate) : null;
            reset(editUser);
        }
    }, [user]);

    const formContext = useForm({
        defaultValues: new MemberViewModel()
    });
    const { reset } = formContext;


    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUserRole(user.role);
        setLoading(true);

        crabFetch(`User/GetRolesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setRoles(data);
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Team/GetTeamDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                setTeams(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();

        if (form.startDate) {
            const start = new Date(form.startDate);
            start.setHours(12);

            form.startDate = start;
        }

        crabFetch(`Profile/UpdateProfile?userId=${userId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', user ? "Successfully updated profile" : "Successfully created user");
                    onClose(true);
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="sm"
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>Edit Details</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                                <TextFieldElement autoComplete='off' name="firstName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                                <TextFieldElement autoComplete='off' name="lastName" required fullWidth size="small" />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="email" shrink>Email</InputLabel>
                                <TextFieldElement autoComplete='off' name="email" required fullWidth size="small" />
                            </Grid>
                            {(userRole === 'Admin' || userRole === 'Company Admin') &&
                                <>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="startDate" shrink>Start Date</InputLabel>
                                        <DatePickerElement inputProps={{ size: 'small', fullWidth: true }} name={'startDate'} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="roleId" shrink>Role</InputLabel>
                                        <SelectElement
                                            name="roleId"
                                            required
                                            fullWidth
                                            size="small"
                                            options={roles}
                                            InputProps={{
                                                disabled: loading,
                                                startAdornment: (
                                                    !loading ? null : <CircularProgress size={20} />
                                                )

                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor="teamId" shrink>Teams</InputLabel>
                                        <SelectElement
                                            name="teamId"
                                            required
                                            fullWidth
                                            size="small"
                                            options={team}
                                            InputProps={{
                                                disabled: loading,
                                                startAdornment: (
                                                    !loading ? null : <CircularProgress size={20} />
                                                )

                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <InputLabel htmlFor="hasEmailNotifications" shrink>Email Notifications</InputLabel>
                                <CheckboxElement name="hasEmailNotifications" label={undefined} />
                            </Grid>
                        </Grid>
                    </LocalizationProvider >
                    <DialogActions>
                        <LoadingButton loading={loading} variant="contained" type="submit">Save</LoadingButton>
                        <Button disabled={loading} onClick={() => onClose(false)} color="error">Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}