import * as React from 'react';
import Grid from '@mui/material/Grid';
import CompanyDetails from './CompanyDetails';
import Paper from '@mui/material/Paper';

export default function CompanySettings() {

    return (
        <Paper>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <CompanyDetails />
                </Grid>
            </Grid>
        </Paper>
    );
}