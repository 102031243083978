import * as React from 'react';
import TeamListViewModel from './Viewmodels/TeamListViewModel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { Link, LinkProps } from 'react-router-dom';

interface IProps {
    row: TeamListViewModel;
    editTeam: (id: number) => void;
    archiveTeam: (id: number) => void;
}

export default function TeamTableRow(props: IProps) {
    const { row, editTeam, archiveTeam } = props;
    const link = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>((props, ref) => (<Link {...props} to='/' state={{ teamId: row.id }} ref={ref} />));

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.manager}</TableCell>
            <TableCell align="right">{row.members}</TableCell>
            <TableCell align="right">
                <Button sx={{ mr: 1 }} size='small' variant='contained' component={link}>View</Button>
                <Button sx={{ mr: 1 }} size='small' variant='contained' onClick={() => editTeam(row.id)}>Edit</Button>

                {!row.defaultTeam  &&
                    <Button size='small' variant='contained' onClick={() => archiveTeam(row.id)}>Remove</Button>
                }
            </TableCell>
        </TableRow>
    );
}