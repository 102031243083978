import * as React from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import EventRequestTableViewModel from './Viewmodels/EventRequestTableViewModel';
import { FormContainer } from 'react-hook-form-mui';
import * as Icons from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import dayjs from 'dayjs';

interface IProps {
    request?: EventRequestTableViewModel;
    requestId?: number;
    onClose: (refresh: boolean) => void;
    open?: boolean;
    setTeam?: (id: number) => void;
}

export default function ViewEventRequestDialog(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { request, requestId, onClose, open, setTeam } = props;
    const [role, setRole] = React.useState<string | null>(null);
    const [reason, setReason] = React.useState<string | null>('');
    const [isManager, setIsManager] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [remainingEntitlement, setRemainingEntitlement] = React.useState(0);
    const [requestData, setRequestData] = React.useState(new EventRequestTableViewModel());
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (request)
            setRequestData(request);
    }, [request]);

    React.useEffect(() => {
        if (requestData.id > 0) {
            getData();
            getEntitlment();

            if (setTeam)
                setTeam(requestData.teamId);
        }

        setReason('');
    }, [requestData]);

    React.useEffect(() => {
        if (requestId) {
            getRequest();
        }
    }, [requestId]);

    const getRequest = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Event/GetStaffRequest?id=${requestId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data) => {
                setRequestData(data);
            }
        );
    }

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setRole(user.role);
        setUserId(user.sub);

        crabFetch(`Team/GetIsTeamManager?teamId=${requestData.teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data) => {
                setIsManager(data);
            }
        );
    }

    const getEntitlment = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Staff/GetRemainingEntitlement?userId=${requestData.requestedById}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data) => {
                setRemainingEntitlement(data);
            }
        );
    }

    const handleReasonValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setReason(event.target.value);
    }

    const submit = async (status: boolean) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Event/UpdateRequest?id=${requestData.id}&status=${status}&reason=${reason}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Updated request");
                    onClose(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const remove = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        crabFetch(`Event/RemoveUserEvent?userId=${user.sub}&eventId=${requestData.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully removed event");
                    onClose(true);
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const Icon = React.useMemo(() => {
        return requestData.icon.length > 0 ? Icons[requestData.icon] ?? null : null;
    }, [requestData.icon]);

    const daysUsed = React.useMemo(() => {
        if (requestData.id > 0) {
            const from = dayjs(requestData.from);
            const to = dayjs(requestData.to);

            if (from.isSame(to, "d")) {
                return !requestData.fullEnd && !requestData.fullStart ? 0.5 : 1;
            }

            let days = Math.abs(dayjs(requestData.to).diff(new Date(requestData.from), "d"));

            if (!requestData.fullStart)
                days = days + 0.5;

            if (!requestData.fullEnd)
                days = days + 0.5;
            else
                days = days + 1;

            return days;
        }

        return 0;
    }, [requestData]);

    return (
        <>
            {(open !== undefined && open !== null) ?
                <Dialog
                    open={open}
                    onClose={() => onClose(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item sx={{ display: 'flex' }}>{Icon && <Icon style={{ color: requestData.colour }} />}</Grid>
                            <Grid item>{requestData.type} request from {requestData.name}</Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <FormContainer>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant={"h2"}>Team</Typography>
                                    <Typography>{requestData.team}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"h3"}>From</Typography>
                                    <Typography>{new Date(requestData.from).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })} {requestData?.fullStart === true ? "" : new Date(requestData.from).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit" })}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant={"h3"}>To</Typography>
                                    <Typography>{new Date(requestData.to).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })} {requestData?.fullEnd === true ? "" : new Date(requestData.to).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit" })}</Typography>
                                </Grid>
                                {requestData.note && requestData.note.length > 0 &&
                                    <Grid item xs={12}>
                                        <Typography variant={"h2"}>Request Notes</Typography>
                                        <Typography>{requestData.note}</Typography>
                                    </Grid>
                                }
                                {requestData.affectsEntitlement === 0 &&
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Typography variant={"h2"}>Available Entitlement</Typography>
                                            <Typography>{remainingEntitlement}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant={"h3"}>Entitlement After Approval</Typography>
                                            <Typography>{remainingEntitlement - daysUsed}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {((requestData.id > 0 && role && (role !== 'Staff Member' || isManager) && (userId !== requestData.requestedById)) || requestData.rejectReason && requestData.rejectReason.length > 0) &&
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="Note" shrink>Rejection Reason</InputLabel>
                                        <TextField autoComplete='off' defaultValue={requestData.rejectReason!} disabled={(role === 'Staff Member' && !isManager)} onChange={(e) => handleReasonValue(e)} required name="rejectReason" fullWidth size="small" multiline minRows={4} />
                                    </Grid>
                                }
                                {(requestData.id > 0 && role && (role !== 'Staff Member' || isManager) && (userId !== requestData.requestedById)) &&
                                    <Grid item xs={12} >
                                        <DialogActions>
                                            <LoadingButton loading={loading} variant="contained" onClick={() => submit(true)} fullWidth>Approve Request</LoadingButton>
                                            <LoadingButton loading={loading} disabled={reason!.length === 0} variant="outlined" onClick={() => submit(false)} color="error" fullWidth>Deny Request</LoadingButton>
                                            <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} fullWidth>Close</Button>
                                        </DialogActions>
                                    </Grid>
                                }
                                {(requestData.id > 0 && role && ((role === 'Staff Member' && !isManager) || (userId === requestData.requestedById))) &&
                                    <Grid item xs={12} >
                                        <DialogActions>
                                            {requestData.status === "Pending" &&
                                                <Button variant="contained" onClick={() => remove()} color="error" fullWidth disabled={loading}>Remove Request</Button>
                                            }
                                            <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} fullWidth>Close</Button>
                                        </DialogActions>
                                    </Grid>
                                }
                            </Grid>
                        </FormContainer>
                    </DialogContent>
                </Dialog>
                :
                <>
                    {requestId !== undefined &&
                        <Paper>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item sx={{ display: 'flex' }}>{Icon && <Icon style={{ color: requestData.colour }} />}</Grid>
                                <Grid item><Typography variant="h2">{requestData.type} request from {requestData.name}</Typography></Grid>
                                <Grid item xs={12}>
                                    <Divider variant="fullWidth" sx={{ marginX: -2 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormContainer>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant={"h3"}>Team</Typography>
                                                <Typography>{requestData.team}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant={"h3"}>From</Typography>
                                                <Typography>{new Date(requestData.from).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })} {requestData?.fullStart === true ? "" : new Date(requestData.from).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit" })}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant={"h3"}>To</Typography>
                                                <Typography>{new Date(requestData.to).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })} {requestData?.fullEnd === true ? "" : new Date(requestData.to).toLocaleTimeString('en-GB', { hour: "2-digit", minute: "2-digit" })}</Typography>
                                            </Grid>
                                            {requestData.note && requestData.note.length > 0 &&
                                                <Grid item xs={12}>
                                                    <Typography variant={"h3"}>Request Notes</Typography>
                                                    <Typography>{requestData.note}</Typography>
                                                </Grid>
                                            }
                                            <Grid item xs={6}>
                                                <Typography variant={"h3"}>Available Entitlement</Typography>
                                                <Typography>{remainingEntitlement}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant={"h3"}>Entitlement After Approval</Typography>
                                                <Typography>{remainingEntitlement - daysUsed}</Typography>
                                            </Grid>
                                            {((requestData.id > 0 && role && (role !== 'Staff Member' || isManager) && (userId !== requestData.requestedById)) || requestData.rejectReason && requestData.rejectReason.length > 0) &&
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="Note" shrink>Rejection Reason</InputLabel>
                                                    <TextField autoComplete='off' defaultValue={requestData.rejectReason!} disabled={(role === 'Staff Member' && !isManager)} onChange={(e) => handleReasonValue(e)} required name="rejectReason" fullWidth size="small" multiline minRows={4} />
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <Divider variant="fullWidth" sx={{ marginX: -2 }} />
                                            </Grid>
                                            {(requestData.id > 0 && role && (role !== 'Staff Member' || isManager) && (userId !== requestData.requestedById)) &&
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        <LoadingButton loading={loading} variant="contained" onClick={() => submit(true)} fullWidth>Approve Request</LoadingButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LoadingButton loading={loading} disabled={reason!.length === 0} variant="outlined" onClick={() => submit(false)} color="error" fullWidth>Deny Request</LoadingButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} fullWidth>Close</Button>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                            {(requestData.id > 0 && role && ((role === 'Staff Member' && !isManager) || (userId === requestData.requestedById))) &&
                                                <React.Fragment>
                                                    <Grid item xs={12}>
                                                        {requestData.status === "Pending" &&
                                                            <Button variant="contained" onClick={() => remove()} color="error" fullWidth disabled={loading}>Remove Request</Button>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} fullWidth>Close</Button>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </FormContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                    }

                </>


            }
        </>
    );
}