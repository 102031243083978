import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MonthView from './MonthView';
import { useLocation } from 'react-router-dom';
import EventList from './Events/EventList';
import EventRequestList from './Events/EventRequestList';
import authService from '../api-authorization/AuthorizeService';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import OnboardStaffDialog from './OnboardStaffDialog';
import MonthlyEventsViewModel from './Viewmodels/MonthlyEventsViewModel';
import ViewEventRequestDialog from '../Event/ViewEventRequestDialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function CompanyDashboard() {
    let state: any = useLocation();
    const theme = useTheme();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [team, setTeam] = React.useState<number | null>(state.state ? state.state.teamId : null);
    const [dateFilter, setDateFilter] = React.useState<Date>(new Date());
    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [staff, setStaff] = React.useState<MonthlyEventsViewModel[]>([]);
    const [openReview, setOpenReview] = React.useState<number | null>(null);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Staff/GetDashboardNumber`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data) => {
                setTeam(data === "" ? 0 : data);
            }
        );

        crabFetch(`Company/CheckStaffCountForOnboardDialog`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data) => {
                if (data != null) {
                    setOpen(!data);
                }

            }
        );
    }

    const getStaff = async (monthFilter: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Event/GetMonthlyStaffEvents?dateFilter=${monthFilter.toISOString()}${team ? `&teamId=${team}` : ``}&getYourCalendar=${false}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setStaff(data);
            }
        );
    }

    const setOnboardTrue = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Company/SetOnboard`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        setOpen(false);
        getStaff(new Date());
        setOpenReview(null);

        if (refresh) {
            setOnboardTrue();
        }
    }

    const closeReviewDialog = (refresh: boolean) => {
        setOpenReview(null);

        if (refresh) {
            getStaff(dateFilter);
            setOnboardTrue();
        }
    }

    const viewRequest = (id: number | null, date: Date | null, teamId: number | null) => {
        setOpenReview(id);

        if (date)
            setTimeout(() => { setDateFilter(new Date(date)); }, 1100)
           

        if (teamId)
            setTeam(teamId);
    }

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
                    {team !== null &&
                        <Grid item xs={12} md={12} style={{ direction: 'rtl', transition: 'all 1s 0.2s', flex: '1 1 100%', width: '1%' }}>
                            <Paper style={{ direction: 'ltr' }} >
                                <MonthView dateFilter={dateFilter} changeDateFilter={setDateFilter} team={team} changeTeamFilter={setTeam} staff={staff} getStaff={getStaff} setStaff={setStaff} loading={loading} setLoading={setLoading} selectedRequest={openReview} viewRequest={viewRequest} />
                            </Paper>
                        </Grid>
                    }
                    {largeScreen ?
                        <Grid item xs style={openReview ? { direction: 'ltr', transition: 'all 1s 0.2s', width: '366px', maxWidth: '366px', overflow: 'hidden', flex: '0 0 366px', minHeight: '100%' } : { direction: 'rtl', transition: 'all, 0.5s', width: 0, paddingLeft: 0, overflow: 'hidden', minHeight: '100%' }}>
                            <div style={{ direction: 'ltr', transition: 'width 1s', /*maxHeight: 500,*/ minWidth: 350, minHeight: '100%', overflow: 'hidden' }}>
                                <ViewEventRequestDialog onClose={closeReviewDialog} requestId={openReview ? openReview : undefined} setTeam={setTeam} />
                            </div>
                        </Grid>
                        :
                        <ViewEventRequestDialog open={openReview !== null && openReview > 0} onClose={closeReviewDialog} requestId={openReview ? openReview : undefined} setTeam={setTeam} />
                    }
                </Grid>
            </Grid>
            {team !== null &&
                <>
                    <Grid item xs={12} sm={6}>
                        <EventList dateFilter={dateFilter} teamView={team} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <EventRequestList teamView={team} selected={openReview} viewRequest={viewRequest} />
                    </Grid>
                </>
            }
            <OnboardStaffDialog open={open} onClose={closeDialog} />
        </>
    );
}