import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IProps extends CircularProgressProps {
    percentage: number;
}

export default function CircularGaugeChart(props: IProps) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: '#F2F4F8',
                }}
                size={40}
                thickness={3}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                color='primary'
                sx={{
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={40}
                thickness={3}
                {...props}
                value={props.percentage}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography>{`${Math.round(props.percentage)}%`}</Typography>
            </Box>
        </Box>
    );
}