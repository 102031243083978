import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';
import authService from '../../api-authorization/AuthorizeService';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UserEventViewModel from '../Viewmodels/UserEventViewModel';
import EventTypeDropdownViewModel from '../../Event/Viewmodels/EventTypeDropdownViewModel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { partialDayOptions } from '../../Utilities/CalendarEnums';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId: string;
    startDate: Date;
    endDate: Date;
    userStartDate: Date;
    userLeaveDate?: Date | null;
}

export default function CreateUserEvent(props: IProps) {
    const { open, onClose, userId, startDate, endDate, userStartDate, userLeaveDate } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [eventTypes, setEventTypes] = React.useState<EventTypeDropdownViewModel[]>([]);
    const [saving, setSaving] = React.useState(false);
    const [openConfirmation, setopenConfirmation] = React.useState(false);
    const [isRequest, setIsRequest] = React.useState(false);
    const [finalForm, setFinalForm] = React.useState<UserEventViewModel>();
    const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
    const [endTime, setEndTime] = React.useState<Dayjs | null>(null);

    const formContext = useForm({
        defaultValues: new UserEventViewModel()
    });
    const { reset, watch, setValue } = formContext;
    const watchStartDate = watch('start', dayjs(startDate));
    const watchEndDate = watch('end', dayjs(endDate));
    const watchEventType = watch('eventType', 0);

    React.useEffect(() => {
        getData();
    }, [userId]);

    React.useEffect(() => {
        if (eventTypes.length > 0) {
            let defaultEventType = eventTypes.find(w => w.name === 'Holiday')?.id ?? null;
            setValue("eventType", defaultEventType);
        }
    }, [eventTypes]);

    React.useEffect(() => {
        const newEvent = new UserEventViewModel();
        newEvent.userId = userId;
        newEvent.start = dayjs(startDate);
        newEvent.end = dayjs(endDate);

        reset(newEvent);
    }, [userId, startDate, endDate]);

    React.useEffect(() => {
        if (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') < 12) {
            if (startTime && endTime && endTime < startTime) {
                setEndTime(startTime.add(1, 'h'));
            }
        }
    }, [startTime, endTime]);

    React.useEffect(() => {
        if (eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
            setValue("end", watchStartDate);
        } 

        setValue("startDayType", 0);
        setValue("endDayType", 0);
    }, [watchEventType, watchStartDate, watchEndDate]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();

        setIsRequest(user.sub === userId && user.role === 'Staff Member');

        crabFetch(`Event/GetEventTypeDropdown?id=${userId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setEventTypes(data);
            }
        );
    }

    const checkForOverwrite = async (form: any) => {
        const token = await authService.getAccessToken();

        if (form.start) {
            const start = new Date(form.start);
            start.setHours(12);

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                start.setHours(startTime!.hour());
                start.setMinutes(startTime!.minute());
            }

            form.start = start;
        }

        if (form.end) {
            const end = new Date(form.end);
            end.setHours(12);

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                end.setHours(endTime!.hour());
                end.setMinutes(endTime!.minute());
            }

            form.end = end;
        }

        crabFetch('Event/CheckForOverwrite', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data === "true") {
                    setFinalForm(form);
                    setopenConfirmation(true);
                }
                else if (data === "false") {
                    submit(form);
                }
                else {
                    show('error', data);
                }
                setSaving(false);
            },
            (error: any) => {
                show('error', error);
                setSaving(false);
            }
        );
    }

    const submit = async (formData: any) => {
        setSaving(true);
        const token = await authService.getAccessToken();
        const form = { ...formData };

        if (form.start) {
            const start = new Date(form.start);
            start.setMinutes(start.getMinutes());

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                start.setHours(startTime!.hour());
                start.setMinutes(startTime!.minute());
            }

            form.start = start;
        }

        if (form.end) {
            const end = new Date(form.end);
            end.setMinutes(end.getMinutes());

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                end.setHours(endTime!.hour());
                end.setMinutes(endTime!.minute());
            }

            form.end = end;
        }

        form.userId = userId;

        // Prevent submitting if positive or toil event and no times entered
        if (eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
            if (!endTime?.isValid() || !startTime?.isValid()) {
                setSaving(false);
                show('error', "Start and end times are required for this type of event.");

                return;
            }

            form.startDayType = startTime.hour() < 12 ? 1 : 2;
            form.endDayType = startTime.hour() < 12 ? 1 : 2;
        }

        crabFetch('Event/CreateUserEvent', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created new event");
                    closeDialog(true);
                }
                setSaving(false);
            },
            (error: any) => {
                show('error', error);
                setSaving(false);
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        const newEvent = new UserEventViewModel();
        newEvent.start = dayjs(startDate);
        newEvent.end = dayjs(endDate);
        setStartTime(null);
        setEndTime(null);

        reset(newEvent);
        onClose(refresh);
    }


    const closeConfimation = (refresh: boolean) => {
        if (refresh) {
            submit(finalForm);
        }
        setopenConfirmation(false);
    }

    const startTypeOptions = React.useMemo(() => {
        if (dayjs.isDayjs(watchEndDate) && dayjs(watchStartDate).isSame(watchEndDate))
            return partialDayOptions;
        else
            return partialDayOptions.filter(f => f.id !== 1);
    }, [watchStartDate, watchEndDate]);

    const endTypeOptions = React.useMemo(() => {
        if (dayjs.isDayjs(watchEndDate) && dayjs(watchStartDate).isSame(watchEndDate))
            return partialDayOptions;
        else
            return partialDayOptions.filter(f => f.id !== 2)
    }, [watchStartDate, watchEndDate]);

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={checkForOverwrite}
            >
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>{isRequest ? "Make Event Request" : "Add Staff Event"}</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={12}>
                                <InputLabel htmlFor="eventType" shrink>Request Type</InputLabel>
                                {eventTypes &&
                                    <SelectElement name="eventType" required labelKey="name" options={eventTypes} fullWidth size="small" />
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="start" shrink>First Day Of Event</InputLabel>
                                <DatePickerElement minDate={dayjs(userStartDate)} maxDate={dayjs(userLeaveDate)} onChange={(e) => { if (watchEndDate.isBefore(e, "d")) setValue("end", e); }} name="start" required inputProps={{ fullWidth: true, size: "small" }} textReadOnly />
                            </Grid>
                            {(eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)!.requiresTime) ?
                                <Grid item xs={12} md={6}>
                                    <TimeField fullWidth size="small" format="HH:mm" value={startTime} onChange={(newValue: Dayjs | null) => setStartTime(newValue)} />
                                </Grid>
                                :
                                <Grid item xs={12} md={6}>
                                    <SelectElement name="startDayType" required fullWidth size="small" options={startTypeOptions} />
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="start" shrink>Last Day Of Event</InputLabel>
                                <DatePickerElement minDate={watchStartDate} maxDate={dayjs(userLeaveDate)} name="end" required inputProps={{ fullWidth: true, size: "small" }} disabled={(watchEventType != null && watchEventType > 0) && (eventTypes.length > 0 && eventTypes.find(f => f.id === watchEventType)!.requiresTime)} textReadOnly />
                            </Grid>
                            {(eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)!.requiresTime) ?
                                <Grid item xs={12} md={6}>
                                    <TimeField required fullWidth size="small" format="HH:mm" value={endTime} onChange={(newValue: Dayjs | null) => setEndTime(newValue)} />
                                </Grid>
                                : (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') > 12) ?
                                    <Grid item xs={12} md={6}>
                                        <SelectElement name="endDayType" required fullWidth size="small" options={endTypeOptions} />
                                    </Grid>
                                    :
                                    <React.Fragment />
                            }
                            <Grid item xs={12}>
                                <InputLabel htmlFor="Note" shrink>Note</InputLabel>
                                <TextFieldElement autoComplete='off' name="note" fullWidth size="small" multiline minRows={4} />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <DialogActions>
                        <LoadingButton variant="contained" type="submit" fullWidth loading={saving}>{isRequest ? "Send Request" : "Create Event"}</LoadingButton>
                        <Button variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth disabled={saving}>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation confirmClosureMessage={'Yes'} preventClosureMessage={'No'} title={'This event is set to overwrite existing events, are you sure?'} open={openConfirmation} onClose={closeConfimation} />
        </Dialog>
    );
}