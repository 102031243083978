import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import Button from '@mui/material/Button';
import MemberViewModel from './Viewmodels/MemberViewModel';
import EditStaff from './EditStaff';
import SetLeaveDate from './SetLeaveDate';
import { AlertContext } from '../Contexts/AlertContext';
import ChangePassword from '../Profile/ChangePassword';

interface IProps {
    userId: string;
}

export default function StaffDetails(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [user, setUser] = React.useState(new MemberViewModel());
    const [loading, setLoading] = React.useState(true);
    const [openEditStaff, setOpenEditStaff] = React.useState(false);
    const [openLeaveDate, setOpenLeaveDate] = React.useState(false);
    const [showAdminControls, setShowAdminControls] = React.useState(false);
    const [showResetPassword, setShowResetPassword] = React.useState(false);

    React.useEffect(() => {
        if (userId.length > 0)
            getData();
    }, [userId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setShowAdminControls(user.role !== "Staff Member");
        setShowResetPassword(user.sub !== userId);

        crabFetch(`Staff/GetStaffMember?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setLoading(false);
                setUser(data);
            },
            () => {
                setLoading(false);
            }
        );
    }


    const reset = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`User/ResetPassword?userId=${userId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent reset Email`);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        if (refresh) getData();
        setOpenEditStaff(false);
        setOpenLeaveDate(false);
    }


    return (
        <>
            <Typography sx={{ pb: 1 }} variant="h2">Personal Details</Typography>
            <Paper>
                <Grid container spacing={2}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Forename:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.firstName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Surname:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.lastName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Email:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ wordWrap: "break-word" }} variant="h4">{user.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Team:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.teamName}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Start Date:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.startDate ? new Date(user.startDate.toString()).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" }) : ''}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Leave Date:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.leaveDate ? new Date(user.leaveDate).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" }) : 'N/A'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Role:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">{user.role}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 1 }}>
                        <Button size='small' onClick={() => setOpenEditStaff(true)} variant="contained" color="primary" fullWidth disabled={loading}>Edit Details</Button>
                    </Grid>
                    {showAdminControls &&
                        <>
                            <Grid item xs={12} sx={{ pt: 1 }}>
                                <Button size='small' onClick={() => setOpenLeaveDate(true)} variant="contained" color="primary" fullWidth disabled={loading}>Set Leave Date</Button>
                            </Grid>
                            {showResetPassword &&
                                <Grid item xs={12} sx={{ pt: 1 }}>
                                    <Button size='small' onClick={reset} variant="contained" color="primary" fullWidth disabled={loading}>Reset Password</Button>
                                </Grid>
                            }
                        </>
                    }
                    <Grid item xs={12}>
                        <ChangePassword userId={userId} />
                    </Grid>
                </Grid>
                <EditStaff open={openEditStaff} onClose={closeDialogs} user={user} userId={props.userId}></EditStaff>
                <SetLeaveDate open={openLeaveDate} onClose={closeDialogs} userId={[userId]}></SetLeaveDate>
            </Paper>
        </>
    );
}